<template>
  <div id="edit-view">
    <!-- Add Model version modal -->
    <b-modal
      id="add-version"
      ref="modal"
      title="Manage Version"
      @show="resetModal(false)"
      @hidden="resetModal(true)"
      @ok="handleOk"
    >
      <form>
        <div class="form-group has-feedback">
          <input
            id="version-name-input"
            class="form-control"
            type="text"
            v-model="version.name"
            required
          />
          <label @click="utils.focus('version-name-input')" class="form-label"
            >Name</label
          >
        </div>
        <div class="form-group has-feedback mt-5">
          <multiselect
            v-model="selectedApplication"
            :options="filteredApplications"
            :close-on-select="true"
            :preserve-search="true"
            :internal-search="false"
            :searchable="true"
            placeholder="Select an application for your model version"
            :show-labels="false"
            @search-change="filterApplications"
          >
            <template slot="singleLabel" slot-scope="props"
              ><span
                class="option__title"
                v-html="utils.getApplicationName(props.option)"
              ></span
            ></template>
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span
                  class="option__title"
                  v-html="utils.getApplicationName(props.option)"
                ></span>
              </div>
            </template>
            <template slot="noResult">
              <span>No application matches that search...</span>
            </template>
          </multiselect>
          <label class="form-label auto-adjust-form-label">Application</label>
        </div>

        <b-form-group label="Metrics" label-for="metrics-input">
          <div v-for="metric in version.metrics" :key="metric.id">
            <div class="form-group has-feedback mt-3">
              <input
                :id="metric.id"
                class="form-control"
                type="text"
                v-model.number="metric.value"
                required
              />
              <label @click="utils.focus(metric.id)" class="form-label">{{
                metric.name
              }}</label>
            </div>
          </div>
        </b-form-group>
        <div class="form-group has-feedback">
          <input
            id="version-dataset-id-input"
            class="form-control"
            type="text"
            v-model="version.dataset_id"
            required
          />
          <label
            @click="utils.focus('version-dataset-id-input')"
            class="form-label"
            >Dataset id</label
          >
        </div>
        <div class="form-group has-feedback">
          <input
            id="version-dataset-version-id-input"
            class="form-control"
            type="text"
            v-model="version.dataset_version_id"
            required
          />
          <label
            @click="utils.focus('version-dataset-version-id-input')"
            class="form-label"
            >Dataset version id</label
          >
        </div>
        <div class="form-group has-feedback">
          <input
            id="version-job-id-input"
            class="form-control"
            type="text"
            v-model="version.job_id"
            required
          />
          <label @click="utils.focus('version-job-id-input')" class="form-label"
            >Job id</label
          >
        </div>

        <div class="form-group has-feedback">
          <input
            id="version-description-input"
            class="form-control"
            type="text"
            v-model="version.description"
            rows="3"
            required
          />
          <label
            @click="utils.focus('version-description-input')"
            class="form-label"
            >Description</label
          >
        </div>
        <div class="form-group has-feedback">
          <input
            id="version-config-input"
            class="form-control"
            type="text"
            v-model="version.config"
            rows="3"
            required
          />
          <label @click="utils.focus('version-config-input')" class="form-label"
            >Config</label
          >
        </div>

        <div class="form-group has-feedback pt-3" v-if="version.id">
          <input
            class="form-control disabled-input sided-with-copy"
            type="text"
            v-model="version.id"
            required
            onkeypress="return false;"
          />
          <label class="form-label pt-3">Id</label>
          <a
            class="hover-larger pointer"
            @click="copyToClipboard(version.id)"
            @mouseout="resetCopyLabel()"
            v-b-popover.hover.top="getCopyLabel()"
            ><i class="fas fa-copy"
          /></a>
        </div>
        <hr />
        <div class="date">
          Updated at: {{ utils.showDate(version.updated_at) }}
        </div>
        <div class="date">
          Created at: {{ utils.showDate(version.created_at) }}
        </div>
      </form>
    </b-modal>
    <section class="main pt-3">
      <SimpleModal
        :title="deleteModalTitle"
        :name="deleteName"
        :ok="modalOk"
        :hidden="cancelDelete"
      ></SimpleModal>
      <Header
        :entity="model"
        :action="updateModel"
        :actionLabel="actionLabel"
      />
      <div class="container-fluid mt-5">
        <div class="overview pl-2 pt-1">
          <div class="my-4">
            <span class="title">Model info</span>
            <button
              class="second-action-button float-right action-btn"
              @click="showModel(model)"
            >
              Use
            </button>
          </div>

          <div class="row">
            <div class="col">
              <form class="mt-4">
                <div class="form-group has-feedback">
                  <input
                    id="model-name-input"
                    class="form-control"
                    type="text"
                    v-model="model.name"
                    required
                  />
                  <label
                    @click="utils.focus('model-name-input')"
                    class="form-label"
                    >Name</label
                  >
                </div>
                <div class="form-group has-feedback mt-5">
                  <textarea
                    id="model-description-input"
                    class="form-control"
                    v-model="model.description"
                    required
                    rows="10"
                  ></textarea>
                  <label
                    @click="utils.focus('model-description-input')"
                    class="form-label"
                    >Description</label
                  >
                </div>
                <div class="form-group has-feedback pt-3" v-if="model.id">
                  <input
                    class="form-control disabled-input sided-with-copy"
                    type="text"
                    v-model="model.id"
                    required
                    onkeypress="return false;"
                  />
                  <label class="form-label pt-1">Id</label>
                  <a
                    class="hover-larger pointer"
                    @click="copyToClipboard(model.id)"
                    @mouseout="resetCopyLabel()"
                    v-b-popover.hover.top="getCopyLabel(true)"
                    ><i class="fas fa-copy"
                  /></a>
                </div>
              </form>
            </div>
            <div class="col">
              <div
                class="
                  d-flex
                  align-items-center
                  file-wrapper
                  align-self-center
                  h-100
                "
              >
                <div
                  class="drop-zone mx-auto"
                  @click="selectImage"
                  @dragover="dragover"
                  @dragend="enddrag"
                  @dragleave="enddrag"
                  @drop="drop"
                >
                  <span v-if="!model.has_logo" class="drop-zone__prompt"
                    >Drop your logo here or click to upload</span
                  >
                  <img
                    class="drop-zone__thumb"
                    v-if="model.has_logo"
                    v-bind:src="logo_img"
                  />
                  <input
                    id="logo-input"
                    type="file"
                    name="file"
                    accept="image/jpg, image/jpeg, image/png"
                    @change="uploadLogo"
                    class="drop-zone__input"
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div>
            <div class="my-4">
              <span class="title">Versions</span>
              <button
                @click="addOrEditVersion({})"
                type="button"
                class="second-action-button float-right action-btn"
              >
                Add version
              </button>
            </div>
            <b-table
              striped
              hover
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="search"
              :items="versions"
              :fields="version_fields"
              sort-icon-left
              responsive
            >
              <template v-slot:cell(active)="row">
                <a @click="setActiveVersion(row.item)"
                  ><i
                    v-bind:class="[
                      isActiveVersion(row.item)
                        ? 'fas fa-check'
                        : 'far fa-square'
                    ]"
                /></a>
              </template>
              <template v-slot:cell(version)="row">
                <span title="version name"> {{ row.item.version }} </span>
                <span title="version number">
                  ({{ row.item.version_number }})
                </span>
              </template>
              <template v-slot:cell(files)="row">
                <button
                  class="second-action-button small-button"
                  v-if="
                    !canUploadVersion(row.item) &&
                      !canInference(row.item, 'web')
                  "
                  @click="addOrEditVersion(row.item)"
                >
                  Application id
                </button>
                <input
                  :id="'web-input-' + row.item.id"
                  class="no-display"
                  type="file"
                  name="file"
                  accept=".pkl, .weights, .cfg, .names, .traineddata, .gguf, .pt"
                  @change="uploadVersion(row.item, 'web')"
                />

                <a
                  v-if="canUploadVersion(row.item)"
                  v-on:click.stop.prevent
                  class="btn"
                  @click="selectPkl('web', row.item)"
                  :disabled="showUploadSpinner(row.item.id, 'web')"
                >
                  <i
                    class="fa fa-upload"
                    v-if="!showUploadSpinner(row.item.id, 'web')"
                  />
                  <span v-if="showUploadSpinner(row.item.id, 'web')"
                    ><i class="fas fa-spinner fa-pulse"></i
                  ></span>
                </a>
                <a
                  v-if="canInference(row.item, 'web')"
                  v-on:click.stop.prevent
                  @click="downloadVersion(row.item, 'pkl')"
                  class="btn"
                >
                  <i class="fa fa-download" />
                </a>
              </template>
              <template v-slot:cell(manage)="row">
                <a
                  v-on:click.stop.prevent
                  @click="addOrEditVersion(row.item)"
                  class="btn"
                >
                  <i class="far fa-edit" />
                </a>
                <a
                  v-if="!isActiveVersion(row.item) && canDelete(row.item)"
                  v-on:click.stop.prevent
                  @click="startDeleteVersion(row.item)"
                  class="btn"
                >
                  <i class="far fa-trash-alt" />
                </a>
                <span class="btn" v-else>
                  <i
                    class="far fa-trash-alt grey"
                    :title="deleteVersionTitle(row.item)"
                  />
                </span>
              </template>
              <template v-slot:cell(can_inference)="row">
                <button
                  v-if="canInference(row.item, 'web')"
                  class="second-action-button small-button"
                  @click="showVersion(row.item)"
                >
                  Try version
                </button>
              </template>
              <template v-slot:cell(platforms)="row">
                <span
                  v-for="supportedPlatform in supportedPlatforms"
                  v-bind:key="supportedPlatform.name"
                >
                  <a
                    v-if="canInference(row.item, supportedPlatform.name)"
                    v-on:click.stop.prevent
                    @click="downloadVersion(row.item, supportedPlatform.name)"
                    class="btn"
                  >
                    <img
                      :src="getPlatformIcon(supportedPlatform.icon)"
                      class="model-version-platform-icon"
                      :title="supportedPlatform.title"
                    />
                  </a>
                  <span
                    v-if="
                      !canInference(row.item, supportedPlatform.name) &&
                        canInference(row.item, 'web') &&
                        platformSupported(row.item, supportedPlatform.name)
                    "
                  >
                    <a
                      v-on:click.stop.prevent
                      @click="selectPkl(supportedPlatform.name, row.item)"
                      class="btn"
                      :disabled="
                        showUploadSpinner(row.item.id, supportedPlatform.name)
                      "
                    >
                      <img
                        :src="getPlatformIcon(supportedPlatform.icon_grey)"
                        class="model-version-platform-icon unavailable"
                        :title="supportedPlatform.title"
                        v-if="
                          !showUploadSpinner(
                            row.item.id,
                            supportedPlatform.name
                          )
                        "
                      />

                      <span
                        v-if="
                          showUploadSpinner(row.item.id, supportedPlatform.name)
                        "
                        ><i class="fas fa-spinner fa-pulse"></i
                      ></span>
                    </a>

                    <input
                      :id="supportedPlatform.name + '-input-' + row.item.id"
                      class="no-display"
                      type="file"
                      name="file"
                      :accept="supportedPlatform.accepted_extensions"
                      @change="uploadVersion(row.item, supportedPlatform.name)"
                    />
                  </span>
                </span>
              </template>

              <template v-slot:cell(metrics)="row">
                <div
                  v-for="metric in row.item.metrics"
                  v-bind:key="metric.id"
                  class="metrics"
                >
                  <div>
                    <span class="font-weight-bold">{{ metric.name }}:</span>
                    {{ metric.value }}
                  </div>
                </div>
              </template>
              <template v-slot:cell(dataset_version_id)="row">
                <a
                  class="pointer"
                  :href="
                    utils.generateUrl([
                      {
                        name: '#',
                        val: ''
                      },
                      {
                        name: 'datasets',
                        val: row.item.dataset_id
                      },
                      {
                        name: 'versions',
                        val: row.item.dataset_version_id
                      }
                    ])
                  "
                  >{{ getDatasetName(row.item) }}</a
                >
              </template>
              <template v-slot:cell(job_id)="row">
                <a
                  class="pointer"
                  :href="
                    utils.generateUrl([
                      {
                        name: '#',
                        val: ''
                      },
                      {
                        name: 'jobs',
                        val: row.item.job_id
                      }
                    ])
                  "
                  :title="row.item.job_id"
                  >{{ row.item.job_id.substring(0, 5) + "..." }}</a
                >
              </template>
              <template v-slot:cell(application)="row">
                <span
                  class="applicationName"
                  :title="utils.getApplicationTitle(row.item.application_id)"
                  v-html="utils.getApplicationName(row.item.application_id)"
                >
                </span>
              </template>
              <template v-slot:cell(updated_at)="row">
                {{ utils.formatDateAndTime(row.item.updated_at) }}
              </template>
            </b-table>
          </div>
          <hr />
          <div>
            <div class="title">Public</div>
            <custom-checkbox
              title=""
              :entity="model"
              property="public"
              text="Share your model with all SeeMe.ai users."
              input_id="public-input"
            />
          </div>
          <hr />
          <div>
            <div class="title">Shares</div>

            <form class="mt-4">
              <b-table
                striped
                hover
                :items="shares"
                :fields="share_fields"
                sort-icon-left
                responsive
                class="my-4"
              >
                <template v-slot:cell(delete)="row">
                  <a
                    v-if="canDelete(row.item)"
                    v-on:click.stop.prevent
                    @click="startDeleteShare(row.item)"
                    class="btn"
                  >
                    <i class="far fa-trash-alt" />
                  </a>
                  <div v-else>
                    <i class="far fa-trash-alt grey" />
                  </div>
                </template>
              </b-table>

              <div class="d-inline mt-4">
                <div class="form-group w-25 has-feedback">
                  <input
                    id="invite-input"
                    class="form-control d-inline"
                    type="text"
                    v-model="email"
                    required
                  />
                  <label
                    @click="utils.focus('invite-input')"
                    for="invite-input"
                    class="form-label d-inline"
                    >Invite via email</label
                  >
                </div>
                <div class="form-group">
                  <button
                    :disabled="inviteBtnDisabled"
                    @click="invite()"
                    class="second-action-button action-btn d-inline"
                  >
                    Invite
                  </button>
                </div>
              </div>
            </form>
          </div>
          <hr />
          <div>
            <div class="my-4">
              <span class="title">Monitor</span>
              <button
                class="second-action-button float-right action-btn"
                @click="showInferences(model)"
              >
                See predictions
              </button>
            </div>
          </div>
          <hr />
          <div class="title">Additional settings</div>
          <form>
            <custom-checkbox
              title="Automatic conversions"
              :entity="model"
              property="auto_convert"
              text="Convert models to supported platforms on upload."
              input_id="convert-input"
            />
            <custom-checkbox
              title="Enable privacy"
              :entity="model"
              property="privacy_enabled"
              text="Do not store or sync predictions."
              input_id="privacy-input"
            />
            <div class="form-group has-feedback my-4 ml-0">
              <input
                id="config-input"
                class="form-control"
                type="text"
                v-model="model.config"
                required
              />
              <label
                @click="utils.focus('config-input')"
                for="config-input"
                class="form-label"
                >Config</label
              >
            </div>
          </form>
          <hr />
          <div class="date">
            Updated at: {{ utils.showDate(model.updated_at) }}
          </div>
          <div class="date">
            Created at: {{ utils.showDate(model.created_at) }}
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import * as utils from "../utils/utils.js";

let version_fields = [
  { key: "active" },
  { key: "files" },
  { key: "name", sortable: true },
  { key: "version", sortable: true },
  { key: "can_inference", label: "Predict" },
  { key: "platforms" },
  { key: "metrics" },
  { key: "dataset_version_id", label: "Dataset" },
  { key: "job_id" },
  { key: "application" },
  { key: "updated_at", sortable: true },
  { key: "manage" }
];

let share_fields = [
  { key: "email", sortable: true },
  { key: "firstname", sortable: true, label: "First name" },
  { key: "name", sortable: true },
  { key: "delete" }
];

let defaultCopyIdLabel = "Copy version id to clipboard";
let copiedIdLabel = "Copied version id to clipboard!";
let defaultCopyModelIdLabel = "Copy model id to clipboard";
let copiedModelIdLabel = "Copied model id to clipboard!";

export default {
  data: function() {
    return {
      selectedApplication: {},
      model: {},
      application: {},
      versions: [],
      email: "",
      shares: [],
      checked: undefined,
      logo_img: "",
      version_fields: version_fields,
      share_fields: share_fields,
      version: {},
      unchangedVersion: {},
      nameState: null,
      sortBy: "version_number",
      sortDesc: true,
      search: "",
      appSearch: "",
      datasets: [],
      isAppSearchFocussed: false,
      versionUploading: {},
      platformUploading: "",
      actionLabel: "Save",
      utils: utils,
      copyIdLabel: defaultCopyIdLabel,
      copyModelIdLabel: defaultCopyModelIdLabel,
      filteredApplications: [],
      supportedPlatforms: [
        {
          name: "onnx",
          accepted_extensions: ".onnx",
          icon: "onnxai-icon.svg",
          icon_grey: "onnxai-icon-grey.svg",
          title: "ONNX model"
        },
        {
          name: "onnx_int8",
          accepted_extensions: ".onnx",
          icon: "onnxai-icon-int8.svg",
          icon_grey: "onnxai-icon-int8-grey.svg",
          title: "ONNX Int8 model"
        },

        {
          name: "ios",
          accepted_extensions: ".mlmodel",
          icon: "apple-icon.svg",
          icon_grey: "apple-icon-grey.svg",
          title: "Core ML model"
        },
        {
          name: "tflite",
          accepted_extensions: ".tflite",
          icon: "tensorflow-icon.svg",
          icon_grey: "tensorflow-icon-grey.svg",
          title: "Tensorflow Lite model"
        },
        {
          name: "labels",
          accepted_extensions: ".txt",
          icon: "labels-icon.svg",
          icon_grey: "labels-icon-grey.svg",
          title: "Labels file"
        }
      ],
      deleteModalTitle: "",
      modalOk: this.deleteVersion,
      deleteName: "",
      versionToDelete: {},
      shareToDelete: {}
    };
  },
  methods: {
    getModel: function() {
      var model_id = this.$route.params.id;
      this.$http
        .get(`${utils.modelApi}/${model_id}`)
        .then(response => {
          this.model = response.data;
          this.logo_img = this.getImgUrl(this.model);

          if (
            this.model.user_id == utils.user_id() ||
            this.model.shared_with_me
          ) {
            this.getVersions();
            this.getShares();
            this.$ga.page("edit-model");
            this.getApplications();
          } else {
            this.$router.push("/");
          }
        })
        .catch(err => {
          if (err.status == this.utils.http_status_not_found) {
            this.$router.push({
              name: "NotFound"
            });
          } else {
            this.$router.push({
              name: "Login",
              query: { redirect: this.$route.fullPath }
            });
          }
        });
    },
    getVersions: function() {
      this.$http
        .get(`${utils.modelApi}/${this.model.id}/versions`)
        .then(res => {
          this.versions = res.data;

          var datasetIds = new Set();

          this.versions.forEach(v => {
            // dataset_id can be empty
            if (v.dataset_id) {
              datasetIds.add(v.dataset_id);
            }
          });

          datasetIds.forEach(d_id => {
            this.$http.get(`${utils.datasetsApi}/${d_id}`).then(res => {
              this.datasets.push(res.data);
            });
          });
        });
    },
    handleOk: function() {
      var method = "post";
      var url = `${utils.modelApi}/${this.model.id}/versions`;

      if (this.version.id) {
        method = "put";

        url = `${url}/${this.version.id}`;
      }

      this.version.application_id = this.selectedApplication.id;

      this.$http[method](url, this.version).then(() => {
        this.$bvModal.hide("add-version");
        this.selectedApplication = {};

        this.getVersions();
      });
    },
    getDatasetName: function(version) {
      var ds = this.datasets.find(a => {
        return a.id == version.dataset_id;
      });

      if (!ds) {
        return version.dataset_version_id;
      }

      var ds_version = ds.versions.find(v => {
        return v.id == version.dataset_version_id;
      });

      if (!ds_version) {
        return ds.name;
      }

      return `${ds.name} (${ds_version.name})`;
    },
    resetModal: function(putVersionBack) {
      this.nameState = null;
      if (putVersionBack) {
        this.getVersions();
      }
    },
    addOrEditVersion: function(version = {}) {
      this.version = version;
      this.filteredApplications = this.utils.applications;
      if (version.application_id) {
        this.selectedApplication = utils.getApplication(version.application_id);
      } else {
        this.selectedApplication = {};
      }

      this.$bvModal.show("add-version");
    },
    getApplications: function() {
      this.utils.getApplications(this.$http);
    },
    appVisible: function(application, search) {
      if (search.length < 2) {
        return true;
      }

      let framework = application.framework.toLowerCase();
      let base_framework = application.base_framework.toLowerCase();
      let framework_version = application.framework_version.toLowerCase();
      let base_framework_version = application.base_framework_version.toLowerCase();
      let application_type = application.application.toLowerCase();
      let inference_host = application.inference_host.toLowerCase();
      let application_id = application.id;

      let searchTerms = search.toLowerCase().split(" ");

      var includes = true;

      for (var i = 0; i < searchTerms.length; i++) {
        var s = searchTerms[i];

        if (
          framework.includes(s) ||
          base_framework.includes(s) ||
          framework_version.includes(s) ||
          base_framework_version.includes(s) ||
          application_type.includes(s) ||
          inference_host.includes(s) ||
          application_id.includes(s)
        ) {
          includes = true;
        } else {
          includes = false;
          break;
        }
      }

      return includes;
    },
    appSearchFocussed: function(isFocussed) {
      this.isAppSearchFocussed = isFocussed;
    },
    isActiveVersion: function(version) {
      return this.model.active_version_id == version.id;
    },
    setActiveVersion: function(version) {
      this.model["active_version_id"] = version.id;
      this.updateModel();
    },
    setApplication: function(application) {
      this.appSearch = "";
      this.version.application_id = application.id;
    },
    getShares: function() {
      this.$http
        .get(`${utils.modelApi}/${this.$route.params.id}/share`)
        .then(response => {
          this.shares = response.data;
        })
        .catch(() => {
          utils.redictLogin(this, this.$route.fullPath);
        });
    },
    updateModel: function() {
      this.$http
        .put(`${utils.modelApi}/${this.model.id}`, this.model)
        .then(response => {
          this.checked = response.data.public ? "on" : "off";
        });
    },
    selectImage: function() {
      utils.el("logo-input").click();
    },
    selectPkl: function(platform, version) {
      utils.el(`${platform}-input-${version.id}`).click();
    },
    getImgUrl: function(model) {
      if (model.has_logo) {
        return `${utils.host}/models/${model.id}/download/logo`;
      }

      return require("../img/seeme_placeholder.png");
    },
    getPlatformIcon: function(icon) {
      return require(`../img/${icon}`);
    },
    canUploadVersion: function(version) {
      return !version["can_inference"] && version["application_id"];
    },
    uploadVersion: function(version, platform) {
      var uploadEl = utils.el(`${platform}-input-${version.id}`);
      var uploadFiles = uploadEl.files;
      var formData = new FormData();

      formData.append("file", uploadFiles[0]);

      this.versionUploading = version;
      this.platformUploading = platform;

      this.$http
        .post(
          `${utils.modelApi}/${this.model.id}/versions/${version.id}/upload`,
          formData
        )
        .then(() => {
          this.versionUploading = {};
          this.platformUploading = "";
          this.getModel();
          uploadEl.value = "";
        });
    },
    showUploadSpinner: function(version, platform) {
      return (
        version == this.versionUploading.id &&
        platform == this.platformUploading
      );
    },
    downloadVersion: function(version, assetType) {
      if (assetType == "ios") {
        assetType = "mlmodel";
      }
      this.$http
        .get(
          `${utils.modelApi}/${this.model.id}/versions/${version.id}/download/${assetType}`,
          { responseType: "blob" }
        )
        .then(res => {
          const url = window.URL.createObjectURL(res.bodyBlob);
          const link = document.createElement("a");
          link.href = url;
          if (res.headers.get("content-type") == "text/plain") {
            assetType = "txt";

            if (this.model.kind == "object_detection") {
              assetType = "names";
            }
          }

          let filename = `${version.id}.${assetType}`;

          if (assetType == "onnx_int8") {
            filename = `${version.id}_int8.onnx`;
          }

          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          setTimeout(function() {
            URL.revokeObjectURL(link.href);
          }, 2000);
        });
    },
    canInference: function(version, platform) {
      switch (platform) {
        case "web":
          return version["can_inference"];
        case "ios":
          return version["has_ml_model"];
        case "tflite":
          return version["has_tflite_model"];
        case "onnx":
          return version["has_onnx_model"];
        case "onnx_int8":
          return version["has_onnx_int8_model"];
        case "labels":
          return version["has_labels_file"];
        default:
          return false;
      }
    },
    platformSupported: function(version, platform) {
      if (this.utils.applications.length == 0) {
        return false;
      }
      var app = this.utils.applications.find(a => {
        return a.id == version.application_id;
      });

      if (!app) {
        return false;
      }

      switch (platform) {
        case "web":
          return true;
        case "ios":
          return app["can_convert_to_coreml"];
        case "tflite":
          return app["can_convert_to_tflite"];
        case "onnx":
          return app["can_convert_to_onnx"];
        case "onnx_int8":
          return app["can_convert_to_onnx_int8"];
        case "labels":
          return app["has_labels_file"];
        default:
          return false;
      }
    },
    showVersion: function(version) {
      this.$router.push(`/models/${this.model.id}/versions/${version.id}`);
    },
    uploadLabel: function(version, platform) {
      if (
        this.versionUploading.id == version.id &&
        platform == this.platformUploading
      ) {
        return "Uploading...";
      }
      return "Upload";
    },
    dragover: function(e) {
      e.preventDefault();

      let dropzoneEl = document.querySelector(".drop-zone");
      dropzoneEl.classList.add("drop-zone--over");
    },
    enddrag: function() {
      let dropzoneEl = document.querySelector(".drop-zone");
      dropzoneEl.classList.remove("drop-zone--over");
    },
    drop: function(e) {
      e.preventDefault();

      if (e.dataTransfer.files.length) {
        let inputEl = utils.el("logo-input");
        inputEl.files = e.dataTransfer.files;
        this.uploadLogo();
      }

      let dropzoneEl = document.querySelector(".drop-zone");
      dropzoneEl.classList.remove("drop-zone--over");
    },
    uploadLogo: function() {
      var uploadFiles = utils.el("logo-input").files;
      var formData = new FormData();

      formData.append("file", uploadFiles[0]);
      this.$http
        .post(`${utils.modelApi}/${this.model.id}/upload`, formData)
        .then(res => {
          this.model["has_logo"] = true;
          // Added `Date.now()` to trigger a refresh of the logo, because the url for the logo is always the same atm.
          this.logo_img = this.getImgUrl(this.model) + `?t=${Date.now()}`;

          this.model["has_logo"] = true;
          this.model["logo"] = res.body["logo"];
        });
    },
    canInvite: function() {
      return utils.isValidEmail(this.email);
    },
    invite: function() {
      var share = {
        email: this.email,
        entity_id: this.model.id,
        entity_type: utils.model_share_type
      };

      this.$http
        .post(`${utils.modelApi}/${this.model.id}/share`, share)
        .then(() => {
          this.getShares();
          this.email = "";
        });
    },
    canDelete: function(item) {
      var user_id = utils.user_id();
      // Owner can delete
      if (this.model.user_id == user_id) {
        return true;
      }

      // You can delete your own shares
      if (item.request_user_id == user_id) {
        return true;
      }

      if (item.user_id == user_id) {
        return true;
      }

      return false;
    },
    navigateUrl: function(params) {
      let url = utils.generateUrl(params);

      this.$router.push(url);
    },
    deleteShare: function() {
      this.$http
        .delete(
          `${utils.modelApi}/${this.model.id}/share/${this.shareToDelete.share_id}`
        )
        .then(() => {
          this.getShares();
        });
    },
    deleteVersionTitle: function(version) {
      if (this.isActiveVersion(version)) {
        return "You cannot delete the active version.";
      }

      if (!this.canDelete(version)) {
        return "You do not have permission to delete this version.";
      }
    },
    deleteVersion: function() {
      this.$http
        .delete(
          `${utils.modelApi}/${this.model.id}/versions/${this.versionToDelete.id}`
        )
        .then(() => {
          this.versions = this.versions.filter(v => {
            return v.id != this.versionToDelete.id;
          });
        });
    },
    showModel(model) {
      this.$router.push(`/models/${model.id}`);
    },
    showInferences(model) {
      this.$router.push(`/models/${model.id}/inferences`);
    },

    startDeleteVersion: function(version) {
      this.deleteModalTitle = "Delete model version";
      this.versionToDelete = version;
      this.modalOk = this.deleteVersion;
      this.deleteName = `${version.name} - ${version.version} (${version.version_number})`;
      this.$bvModal.show("simple-modal");
    },
    startDeleteShare: function(share) {
      this.deleteModalTitle = "Delete dataset share";
      this.shareToDelete = share;
      this.modalOk = this.deleteShare;
      this.deleteName = share.email;
      this.$bvModal.show("simple-modal");
    },
    cancelDelete: function() {
      this.versionToDelete = {};
      this.shareToDelete = {};
    },
    resetCopyLabel: function() {
      setTimeout(() => {
        this.copyIdLabel = defaultCopyIdLabel;
        this.copyModelIdLabel = defaultCopyModelIdLabel;
      }, 500);
    },
    getCopyLabel: function(is_model) {
      if (is_model) {
        return this.copyModelIdLabel;
      }
      return this.copyIdLabel;
    },
    copyToClipboard: function(text) {
      this.utils.copyToClipboard(text);

      this.copyIdLabel = copiedIdLabel;
      this.copyModelIdLabel = copiedModelIdLabel;
    },
    filterApplications: function(search) {
      this.filteredApplications = this.utils.applications.filter(a => {
        return this.appVisible(a, search);
      });
    }
  },
  mounted() {
    this.getModel();

    utils.showMenu(true, true, this);
    this.utils.getApplications(this.$http);
    //window.scrollTo(0, 0);
  },
  computed: {
    inviteBtnDisabled: function() {
      if (!this.canInvite()) {
        return true;
      }

      if (!utils.isValidEmail(this.email)) {
        return true;
      }

      return false;
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 1500px;
}

textarea {
  height: 180px;
}

.dropdown {
  position: relative;
  width: 90%;
  max-width: 350px;
  margin: 0 10px;
}

.dropdown-input,
.dropdown-selected {
  width: 100%;
  padding: 10px 16px;
  border: 1px solid transparent;
  background: #edf2f7;
  line-height: 1.5em;
  outline: none;
  border-radius: 8px;
}

.dropdown-input:focus,
.dropdown-selected:hover {
  background: #fff;
  border-color: #e2e8f0;
}

.dropdown-input::placeholder {
  opacity: 0.7;
}

.dropdown-selected {
  font-weight: bold;
  cursor: pointer;
}

.dropdown-list {
  position: absolute;
  width: 100%;
  max-height: 500px;
  margin-top: 4px;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.dropdown-item {
  display: flex;
  width: 100%;
  padding: 11px 16px;
  cursor: pointer;
}

.dropdown-item:hover {
  background: #edf2f7;
}

.dropdown-item-flag {
  max-width: 24px;
  max-height: 18px;
  margin: auto 12px auto 0px;
}

.unavailable {
  -webkit-filter: grayscale(1);
  /* Webkit */
  filter: gray;
  /* IE6-9 */
  filter: grayscale(1);
  /* W3C */
}

.applicationName {
  font-size: 14px;
}
</style>
