<template>
  <div>
    <section class="main">
      <Header
        :entity="headerEntity"
        :actionLabel="actionLabel"
        :action="goToApp"
        :showLogo="true"
      />
      <div class="container-fluid">
        <div class="register-box">
          <div class="row mx-auto text-center register">
            <div class="col bg-light p-5 d-flex justify-content-center mt-5">
              <form v-if="show_input_form" accept-charset="utf-8" novalidate>
                <div v-if="error" class="error">{{ error }}</div>
                <div class="form-group has-feedback">
                  <input
                    id="username-input"
                    class="form-control"
                    type="text"
                    v-model="username"
                    required
                  />
                  <label
                    @click="utils.focus('username-input')"
                    class="form-label"
                    >Username</label
                  >
                </div>
                <div class="form-group has-feedback">
                  <input
                    id="firstname-input"
                    class="form-control"
                    type="text"
                    v-model="firstname"
                    required
                  />
                  <label
                    @click="utils.focus('firstname-input')"
                    class="form-label"
                    >First name</label
                  >
                </div>
                <div class="form-group has-feedback">
                  <input
                    id="name-input"
                    class="form-control"
                    type="text"
                    v-model="name"
                    required
                  />
                  <label @click="utils.focus('name-input')" class="form-label"
                    >Name</label
                  >
                </div>
                <div class="form-group has-feedback">
                  <input
                    id="password-input"
                    class="form-control"
                    type="password"
                    v-model="password"
                    required
                  />
                  <label
                    @click="utils.focus('password-input')"
                    class="form-label"
                    >Password</label
                  >
                </div>
                <div class="form-group has-feedback">
                  <input
                    id="password-repeat-input"
                    class="form-control"
                    type="password"
                    v-model="repeat_password"
                    required
                  />
                  <label
                    @click="utils.focus('password-repeat-input')"
                    class="form-label"
                    >Password repeat</label
                  >
                </div>
                <div class="row mt-4">
                  <div class="col-xs-4 login-button">
                    <button
                      class="action-btn btn-primary"
                      @click="acceptInvite"
                      v-on:click.stop.prevent
                      :disabled="btn_disabled"
                    >
                      {{ register_button_label }}
                    </button>
                  </div>
                </div>
              </form>
              <form v-else>
                <div class="title text-left">{{ result_title }}</div>
                <div class="text-left">
                  {{ result_body
                  }}<a :href="action_url" target="_blank">{{ result_action }}</a
                  >.
                </div>
              </form>
            </div>
          </div>
        </div>
        <hr />

        <div class="container-fluid">
          <div class="row">
            Have an account? <a class="more-link" href="/#/login">Log in</a>
          </div>
          <div class="row">
            Forgot your password?<a class="more-link" href="/#/resetpassword"
              >Reset password</a
            >
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import * as utils from "../utils/utils.js";
import Vue from "vue";

export default {
  data: function() {
    return {
      headerEntity: { name: utils.invitation_label },
      username: "",
      old_username: "",
      firstname: "",
      name: "",
      password: "",
      repeat_password: "",
      error: "",
      register_button_label: utils.accept_label,
      force_disable_btn: false,
      actionLabel: "Go to app",
      utils: utils,
      show_input_form: true,
      result_title: "",
      result_body: "",
      result_action: "",
      action_url: ""
    };
  },
  methods: {
    getInvite: function() {
      this.$http
        .get(
          `${utils.host}/invite/${this.$route.params.user_id}/${this.$route.params.invite_token}`
        )
        .then(response => {
          this.old_username = response.data.username;
        })
        .catch(err => {
          this.show_input_form = false;
          if (err.status == 404) {
            this.result_title = utils.invitation_not_found_label;
            this.result_body = utils.suggest_reset_label;
            this.result_action = utils.contact_support_label;
            this.action_url = utils.contact_support_url;
          } else {
            this.error_msg =
              "An error occured. Please try again or contact support@seeme.ai";
          }
        });
    },
    goToApp: function() {
      this.$router.push("/");
    },
    acceptInvite: function() {
      this.force_disable_btn = true;
      this.register_button_label = "Processing...";
      var accept_invite = {
        new_username: this.username,
        old_username: this.old_username,
        firstname: this.firstname,
        name: this.name,
        password: this.password,
        repeat_password: this.repeat_password,
        user_id: this.$route.params.user_id,
        invite_token: this.$route.params.invite_token
      };

      this.$http
        .post(`${utils.host}/invite`, accept_invite)
        .then(response => {
          this.force_disable_btn = false;
          this.register_button_label = utils.accept_label;
          if (response.data.done == true) {
            localStorage.setItem("apikey", response.data.apikey);
            localStorage.setItem("username", response.data.new_username);
            localStorage.setItem("user_id", response.data.user_id);

            Vue.http.headers.common[
              "Authorization"
            ] = `${response.data.new_username}:${response.data.apikey}`;

            this.$router.push("/");
          } else {
            this.error = response.data.error;
          }
        })
        .catch(error => {
          this.error = error.body.error;
          this.force_disable_btn = false;
          this.register_button_label = utils.accept_label;
        });
    }
  },
  mounted() {
    this.getInvite();
    this.$ga.page("invite");
    utils.showMenu(false, false, this);
  },
  computed: {
    btn_disabled: function() {
      if (this.force_disable_btn) {
        return this.force_disable_btn;
      }

      if (this.username.length == 0) {
        return true;
      }

      if (this.password.length == 0) {
        return true;
      }

      if (this.repeat_password.length == 0) {
        return true;
      }

      if (this.password != this.repeat_password) {
        return true;
      }

      return false;
    }
  }
};
</script>

<style scoped>
.error {
  color: red;
}
</style>
