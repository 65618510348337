import Vue from "vue";
import VueRouter from "vue-router";
import Models from "../views/Models.vue";
import Login from "../views/Login.vue";
import Model from "../views/Model.vue";
import EditModel from "../views/EditModel.vue";
import ModelInferences from "../views/ModelInferences";
import Register from "../views/Register.vue";
import Invite from "../views/Invite.vue";
import Datasets from "../views/Datasets.vue";
import EditDataset from "../views/EditDataset";
import Dataset from "../views/Dataset";
import Jobs from "../views/Jobs";
import EditJob from "../views/EditJob";
import PasswordResetRequest from "../views/PasswordResetRequest";
import PasswordReset from "../views/PasswordReset";
import NotFound from "../views/404";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Models",
    component: Models
  },
  {
    path: "/models/:id",
    name: "ModelDetail",
    component: Model
  },
  {
    path: "/models/:id/versions/:version_id",
    name: "ModelVersions",
    component: Model
  },
  {
    path: "/models/:id/edit",
    name: "EditModel",
    component: EditModel
  },
  {
    path: "/models/:id/inferences",
    name: "ModelsInferences",
    component: ModelInferences
  },
  {
    path: "/datasets",
    name: "Datasets",
    component: Datasets
  },
  {
    path: "/datasets/:id/edit",
    name: "EditDataset",
    component: EditDataset
  },
  {
    path: "/datasets/:id",
    name: "Dataset",
    component: Dataset
  },
  {
    path: "/datasets/:id/versions/:version_id",
    name: "DatasetVersions",
    component: Dataset
  },
  {
    path: "/jobs",
    name: "Jobs",
    component: Jobs
  },
  {
    path: "/jobs/:job_id",
    name: "EditJob",
    component: EditJob
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/register",
    name: "Register",
    component: Register
  },
  {
    path: "/invite/:user_id/:invite_token",
    name: "Invite",
    component: Invite
  },
  {
    path: "/resetpassword",
    name: "PasswordResetRequest",
    component: PasswordResetRequest
  },
  {
    path: "/resetpassword/:user_id/:reset_token",
    name: "PasswordReset",
    component: PasswordReset
  },
  {
    path: "/notfound",
    name: "NotFound",
    component: NotFound
  }
];

const router = new VueRouter({
  routes
});

export default router;
