<template>
  <div>
    <section class="main">
      <Header
        :entity="headerEntity"
        :actionLabel="actionLabel"
        :action="goToApp"
        :showLogo="true"
      />
      <div class="container-fluid">
        <div class="register-box">
          <div class="row mx-auto text-center register">
            <div class="col bg-light p-5 d-flex justify-content-center mt-5">
              <form accept-charset="utf-8" novalidate>
                <label v-if="error_msg" class="error">{{ error_msg }}</label>
                <div class="form-group has-feedback">
                  <input
                    id="username-input"
                    class="form-control"
                    type="text"
                    v-model="username"
                    required="required"
                  />
                  <label
                    @click="utils.focus('username-input')"
                    class="form-label"
                    >Username</label
                  >
                </div>
                <div class="form-group has-feedback">
                  <input
                    id="email-input"
                    class="form-control"
                    type="text"
                    v-model="email"
                    required="required"
                  />
                  <label @click="utils.focus('email-input')" class="form-label"
                    >E-mail</label
                  >
                </div>
                <div class="form-group has-feedback">
                  <input
                    id="firstname-input"
                    class="form-control"
                    type="text"
                    v-model="firstname"
                    required
                  />
                  <label
                    @click="utils.focus('firstname-input')"
                    class="form-label"
                    >First name</label
                  >
                </div>
                <div class="form-group has-feedback">
                  <input
                    id="name-input"
                    class="form-control"
                    type="text"
                    v-model="name"
                    required="required"
                  />
                  <label @click="utils.focus('name-input')" class="form-label"
                    >Name</label
                  >
                </div>
                <div class="form-group has-feedback">
                  <input
                    id="password-input"
                    class="form-control"
                    type="password"
                    v-model="password"
                    required="required"
                  />
                  <label
                    @click="utils.focus('password-input')"
                    class="form-label"
                    >Password</label
                  >
                </div>
                <div class="form-group has-feedback">
                  <input
                    id="password-repeat-input"
                    class="form-control"
                    type="password"
                    v-model="repeat_password"
                    required="required"
                  />
                  <label
                    @click="utils.focus('password-repeat-input')"
                    class="form-label"
                    >Repeat password</label
                  >
                </div>
                <div class="row mt-4">
                  <div class="col-xs-4 login-button">
                    <button
                      class="btn-primary action-btn mx-auto"
                      @click="register"
                      :disabled="btn_disabled"
                    >
                      {{ register_button_label }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <hr />

        <div class="container-fluid">
          <div class="row">
            Have an account? <a class="more-link" href="/#/login">Log in</a>
          </div>
          <div class="row">
            Forgot your password?<a class="more-link" href="/#/resetpassword"
              >Reset password</a
            >
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import * as utils from "../utils/utils.js";
import Vue from "vue";

export default {
  data: function() {
    return {
      headerEntity: { name: utils.registration_label },
      username: "",
      email: "",
      firstname: "",
      name: "",
      password: "",
      repeat_password: "",
      register_button_label: utils.registration_label,
      force_disable_btn: false,
      error_msg: undefined,
      actionLabel: "Go to app",
      utils: utils
    };
  },
  methods: {
    goToApp: function() {
      this.$router.push("/");
    },
    register() {
      this.error_msg = undefined;

      if (!utils.isValidEmail(this.email)) {
        this.error_msg = this.uitls.email_not_valid_label;
        return;
      }

      this.force_disable_btn = true;

      this.register_button_label = this.utils.registration_in_progress;

      var new_user = {
        username: this.username,
        email: this.email,
        firstname: this.firstname,
        name: this.name,
        password: this.password,
        repeat_password: this.repeat_password
      };

      this.$http
        .post(utils.registerApi, new_user)
        .then(res => {
          this.force_disable_btn = true;
          this.register_button_label = this.utils.register_button_label;
          if (res && res.data && res.data.apikey != undefined) {
            localStorage.setItem("apikey", res.data.apikey);
            localStorage.setItem("username", res.data.username);
            localStorage.setItem("user_id", res.data.id);

            Vue.http.headers.common[
              "Authorization"
            ] = `${res.data.username}:${res.data.apikey}`;

            this.$router.push("/");
          } else {
            var data = res.data;
            var reg = data.registration;
            this.username = reg.username;
            this.email = reg.email;
            this.first_name = reg.firstname;
            this.name = reg.name;
            this.error_msg = data.message;
          }
        })
        .catch(err => {
          this.error_msg = err.body.message;

          this.force_disable_btn = false;
          this.register_button_label = utils.registration_label;
        });
    }
  },
  mounted() {
    this.$ga.page("register");
    utils.showMenu(false, false, this);
  },
  computed: {
    btn_disabled: function() {
      if (this.force_disable_btn) {
        return this.force_disable_btn;
      }

      if (this.username.length == 0) {
        return true;
      }

      if (!utils.isValidEmail(this.email)) {
        return true;
      }

      if (this.password.length == 0) {
        return true;
      }

      if (this.repeat_password.length == 0) {
        return true;
      }

      if (this.password != this.repeat_password) {
        return true;
      }

      return false;
    }
  }
};
</script>
