var tmpHost = "https://api.seeme.ai/api/v1";
var tmpGoogleAnalyticsId = "UA-129600202-3";
var isDevTmp = false;
if (
  window.location.host.includes("localhost") ||
  window.location.host.includes("192.168")
) {
  tmpHost = `http://${window.location.hostname}:5001/api/v1`;
  //tmpHost = `http://192.168.0.3:5001/api/v1`;
  tmpGoogleAnalyticsId = "UA-129600202-2";
  isDevTmp = true;
}

export var applications = [];

export const host = tmpHost;
export const googleAnalyticsId = tmpGoogleAnalyticsId;
export const isDev = isDevTmp;

// -- API routes bases --

export const modelApi = `${host}/models`;
export const datasetsApi = `${host}/datasets`;
export const loginApi = `${host}/login`;
export const jobsApi = `${host}/jobs`;
export const registerApi = `${host}/register`;
export const passwordResetApi = `${host}/resetpassword`;
export const applicationsApi = `${host}/applications`;
export const inferencesApi = `${host}/inferences`;

// -- Application labels --

export const email_not_valid_label = "The provided e-mail is invalid.";
export const registration_label = "Register";
export const request_password_label = "Request password reset";
export const registration_in_progress = "Registering...";
export const reset_password_label = "Reset password";
export const reset_requesting = "Requesting...";
export const not_found_label = "Not found";
export const suggest_reset_label = "If needed, you can ";
export const success_label = "Success";
export const success_reset_label = "You can now ";
export const reset_action_label = "reset your password";
export const log_in_action_label = "log in";
export const invitation_label = "Accept invitation";
export const accept_label = "Accept Invite";
export const invitation_not_found_label = "Invite not found";
export const contact_support_label = "contact support";
export const contact_support_url = "mailto:support@seeme.ai";

// -- Dataset content types --

export const dataset_content_type_images = "images";
export const dataset_content_type_text = "text";
export const dataset_content_type_tabular = "tabular";
export const dataset_content_type_ner = "ner";
export const dataset_content_types = [
  dataset_content_type_images,
  dataset_content_type_text,
  dataset_content_type_tabular,
  dataset_content_type_ner
];

// -- http status codes --
export const http_status_not_found = 404;

// -- Dataset formats --
export const dataset_format_folders = "folders";
export const dataset_format_csv = "csv";
export const dataset_format_yolo = "yolo";
export const dataset_format_spacy = "spacy-ner";
export const dataset_formats = [
  dataset_format_folders,
  dataset_format_csv,
  dataset_format_yolo,
  dataset_format_spacy
];

// -- Copy labels --
export const defaultCopyLabel = "Copy job id to clipboard.";

// -- Default entities --

export const defaultJob = {
  items: []
};

// -- file types --
export const file_type_png = "image/png";
export const file_type_jpg = "image/jpg";
export const file_type_jpeg = "image/jpeg";
export const file_type_pdf = "application/pdf";
export const file_type_mp3 = "audio/mpeg";
export const file_type_wav = "audio/wav";

// -- Inference constants --
export const prediction_label = "Predict";
export const prediction_in_progress_label = "Analyzing...";
export const predict_another_label = "Predict another";

export const classify_label = "Classify";
export const classification_in_progress = "Classifying...";
export const classify_another_label = "Classify another";

export const extract_label = "Extract";
export const extraction_in_progress_label = "Extracting...";
export const extract_another_label = "Extract another";

export const ocr_label = "OCR";
export const ocr_in_progress_label = "OCR in progress...";
export const ocr_another_label = "OCR another";

export const text_input_placeholder = "Enter your text..";

export const generate_label = "Generate";
export const generate_in_progress = "Generating...";

export const chat_label = "chat"; // TODO update to icon
export const chat_in_progress = "sent"; // TODO update to icon

export const transcribe_label = "Transcribe";
export const transcribe_in_progress = "Transcribing...";
export const transcribe_another = "Transcribe another";

// -- Inference extensions

export const imageClassificationExtensions = `${file_type_jpg}, ${file_type_jpeg}, ${file_type_png}`; // "image/jpg, image/jpeg, image/png";
export const objectDetectionExtensions = imageClassificationExtensions;
export const opticalCharacterExtensions = `${imageClassificationExtensions}, ${file_type_pdf}`;
export const speechToTextExtensions = `${file_type_mp3}, ${file_type_wav}`;

// -- Job types --
export const job_type_training = "training";

// -- Job item value types --
export const job_value_type_number = "number";
export const job_value_type_text = "text";
export const job_value_type_multi = "multi";
export const job_value_type_boolean = "boolean";

// -- Job states --
export const job_status_waiting = "waiting";
export const job_status_started = "started";
export const job_status_finished = "finished";
export const job_status_error = "error";

export const supported_job_states = [
  job_status_waiting,
  job_status_started,
  job_status_finished,
  job_status_error
];

// -- Sorting options --
export const sort_option_alphabetically = "alphabetically";
export const sort_option_reversed_alphabetically = "reversed_alphabetically";
export const sort_option_index = "index";
export const sort_option_count_high_low = "count_high_low";
export const sort_option_count_low_high = "count_low_high";
export const sort_option_annotation_high_low = "annotation_high_low";
export const sort_option_annotation_low_high = "annotation_low_high";

// -- CSV config options

export const csv_column_names_key = "column_names";
export const csv_separator_key = "csv_separator";
export const csv_default_separator = ",";

// -- Tesseract options

export const tesseract_psm_options = [
  {
    value: "0",
    label: "0: Orientation and script detection (OSD) only."
  },
  {
    value: "1",
    label: "1: Automatic page segmentation with OSD."
  },
  {
    value: "2",
    label:
      "2: Automatic page segmentation, but no OSD, or OCR. (not implemented)"
  },
  {
    value: "3",
    label: "3: Fully automatic page segmentation, but no OSD."
  },
  {
    value: "4",
    label: "4: Assume a single column of text of variable sizes."
  },
  {
    value: "5",
    label: "5: Assume a single uniform block of vertically aligned text."
  },
  {
    value: "6",
    label: "6: Assume a single uniform block of text."
  },
  {
    value: "7",
    label: "7: Treat the image as a single text line."
  },
  {
    value: "8",
    label: "8: Treat the image as a single word."
  },
  {
    value: "9",
    label: "9: Treat the image as a single word in a circle."
  },
  {
    value: "10",
    label: "10: Treat the image as a single character."
  },
  {
    value: "11",
    label:
      "11: Sparse text. Find as much text as possible in no particular order."
  },
  {
    value: "12",
    label: "12: Sparse text with OSD."
  },
  {
    value: "13",
    label:
      "13: Raw line. Treat the image as a single text line,\
        bypassing hacks that are Tesseract-specific."
  }
];

// -- Share types --

export const model_share_type = "models";
export const dataset_share_type = "datasets";

export const default_selection_color = "#EFC400";

var internal_user_id = "";

export function user_id() {
  if (internal_user_id.length == 0) {
    internal_user_id = localStorage.getItem("user_id");
  }
  return internal_user_id;
}

export function el(x) {
  return document.getElementById(x);
}

export function copyToClipboard(text) {
  navigator.clipboard.writeText(text);
}

export function getImgUrl(model) {
  if (model.has_logo) {
    return `${host}/models/${model.id}/logo/${model.logo}`;
  }
  return "";
}

export function redictLogin(el, path) {
  el.$router.push({
    name: "Login",
    query: { redirect: path }
  });
  el.$root.$emit("toggle_menu", false);
}

export function distance(x1, y1, x2, y2) {
  return Math.sqrt(Math.pow(x2 - x1, 2) + (y2 - y1, 2));
}

export function isValidEmail(email) {
  var mailformat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return mailformat.test(email);
}

export function getApplicationId(
  base_framework = "pytorch",
  base_framework_version = "1.12.1",
  framework = "",
  framework_version = "",
  application = "image_classification"
) {
  if (applications.length == 0) {
    return undefined;
  }

  var a = applications.find(a => {
    return (
      (a.base_framework == base_framework &&
        a.base_framework_version == base_framework_version &&
        a.framework == framework &&
        a.framework_version == framework_version &&
        a.application == application) ||
      (a.base_framework.includes(base_framework) &&
        a.base_framework_version == base_framework_version &&
        a.framework == framework &&
        a.framework_version == framework_version &&
        a.application == application)
    );
  });

  return a.id;
}

export function getApplications(http, cb) {
  if (applications.length == 0) {
    http.get(applicationsApi).then(res => {
      applications = res.data;
      if (cb) {
        cb();
      }
    });
  } else {
    if (cb) {
      cb();
    }
  }
}

export function getApplication(application_id) {
  if (typeof application_id == "object") {
    application_id = application_id.id;
  }

  return applications.find(a => {
    return a.id == application_id;
  });
}

export function getApplicationName(application_id, break_point = "<br />") {
  var app = getApplication(application_id);

  if (!app) {
    return "";
  }

  var application_type =
    app["application"].charAt(0).toUpperCase() +
    app["application"].slice(1).replace("_", " ");

  var base_framework = app["base_framework"];
  var base_framework_version = app["base_framework_version"];

  var framework = app["framework"];
  var framework_version = app["framework_version"];

  if (!framework) {
    return `${application_type}${break_point}${base_framework} (${base_framework_version})`;
  }

  return `${application_type}${break_point}${base_framework} (${base_framework_version}) - ${framework} (${framework_version})`;
}

export function getApplicationTitle(application_id) {
  var app = applications.find(a => {
    return a.id == application_id;
  });

  if (!app) {
    return "";
  }

  return app["inference_host"];
}

export function random_color() {
  var randomColor = Math.floor(Math.random() * 16777215).toString(16);
  return "#" + randomColor;
}

export function canEdit(entity) {
  return canDelete(entity) || entity.shared_with_me;
}

export function canDelete(entity) {
  return entity.user_id == user_id();
}

export function clearUser() {
  internal_user_id = "";
}

export function nerCoordinates(coords) {
  return coords.split(" ").map(coordStr => parseInt(coordStr));
}

export function highlight(
  element_id,
  clear_text,
  selection,
  annotations,
  colors,
  default_color,
  hoverAnnotation
) {
  var textEl = document.getElementById(element_id);
  if (textEl) {
    var innerHTML = clear_text;

    var newInnerHTML = "";
    var startIndex = 0;

    var hasSelection = false;

    if (selection.startOffset || selection.startOffset >= 0) {
      hasSelection = true;
    }

    annotations.sort((a, b) => {
      var a_start = nerCoordinates(a.coordinates)[0];
      var b_start = nerCoordinates(b.coordinates)[0];

      return a_start - b_start;
    });

    var copy = annotations.slice();
    if (hasSelection) {
      var s_s = selection.startOffset;

      var error = copy.find(a => {
        var coords = nerCoordinates(a.coordinates);

        var a_s = coords[0];
        var a_e = coords[1];

        return a_s <= s_s && s_s <= a_e;
      });

      if (error) {
        return error;
      }

      var unlabelled = {
        coordinates: `${selection.startOffset} ${selection.endOffset}`,
        label_id: "unlabelled"
      };
      copy.push(unlabelled);
    }

    var sorted = copy.sort((a, b) => {
      var a_start = nerCoordinates(a.coordinates)[0];
      var b_start = nerCoordinates(b.coordinates)[0];

      return a_start - b_start;
    });

    sorted.forEach(a => {
      var startEnd = nerCoordinates(a.coordinates);

      var start = startEnd[0];
      var end = startEnd[1];

      var color = colors[a.label_id] ? colors[a.label_id] : default_color;

      var fontWeight = "normal";

      if (hoverAnnotation && hoverAnnotation.id == a.id) {
        fontWeight = "bold";
      }

      newInnerHTML +=
        innerHTML.substring(startIndex, start) +
        `<span style='background: ${color}; font-weight: ${fontWeight}'>` +
        innerHTML.substring(start, end) +
        "</span>";
      startIndex = end;
    });

    newInnerHTML += innerHTML.substring(startIndex, innerHTML.length);
    textEl.innerHTML = newInnerHTML;
  }
}

// -- Job Helper methods --

export function js_type_from_value_type(type) {
  return type;
}

export function label_from_job_name(name) {
  name = name.replace("_", " ");

  return name.charAt(0).toUpperCase() + name.slice(1);
}

export function generateUrl(params) {
  var BreakException = {};
  let url = "";
  try {
    params.forEach(p => {
      url += `/${p.name}`;

      if (p.val) {
        url += `/${p.val}`;
      } else if (p.val == "") {
        return;
      } else {
        throw BreakException;
      }
    });
  } catch (e) {
    if (e !== BreakException) throw e;
  }

  return url;
}

export function truncate(value, limit) {
  if (!value) {
    return "";
  }

  if (value.length > limit) {
    value = value.substring(0, limit) + "...";
  }
  return value;
}

export function showDate(dateString) {
  let d = new Date(dateString);

  var locale = "en";

  if (navigator.language) {
    locale = navigator.language;
  }
  return d.toLocaleString(locale);
}

export function showMenu(showMenu, keepPadding, self) {
  var paddingLeft = "0";
  var display = "none";

  if (showMenu) {
    paddingLeft = "350px";
    display = "block";
  }

  if (keepPadding) {
    paddingLeft = "350px";
  }

  el("detail").style.paddingLeft = paddingLeft;
  el("sidebar-menu").style.display = display;

  self.$root.$emit("toggle_menu", showMenu);
}

export function formatTime(date) {
  return new Date(date).toISOString().substr(11, 11);
}

export function formatDateAndTime(dt) {
  let date = new Date(dt);
  const padL = (nr, chr = `0`) => `${nr}`.padStart(2, chr);

  return `${padL(date.getHours())}:${padL(date.getMinutes())}:${padL(
    date.getSeconds()
  )} - ${padL(date.getDate())}/${padL(
    date.getMonth() + 1
  )}/${date.getFullYear()} `;
}

export function focus(element_id) {
  let element = el(element_id);
  if (element && !(document.activeElement === element)) {
    element.focus();
  }
}

// desc: 1 => desc
// desc: -1 => asc
export function sort_dates(arr, prop_name, desc) {
  return arr.sort((a, b) => {
    var date_a = new Date(a[prop_name]);
    var date_b = new Date(b[prop_name]);

    if (date_a < date_b) return desc;
    if (date_b < date_a) return -desc;
    return 0;
  });
}
