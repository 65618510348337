<template>
  <div>
    <form class="w-25 mx-auto" v-show="show_form">
      <div class="">
        <label for="prediction">Prediction:</label>
        <span id="prediction">
          {{ prediction }}
        </span>
      </div>
      <div class="">
        <label for="confidence">Confidence:</label>
        <span id="confidence">
          {{ confidence }}
        </span>
      </div>
    </form>
    <div
      class="drop-zone mx-auto"
      @click="selectImage"
      @dragover="dragover"
      @dragend="enddrag"
      @dragleave="enddrag"
      @drop="drop"
    >
      <span v-if="showDropZone" class="drop-zone__prompt"
        >Drop your image here or click to upload</span
      >
      <img class="drop-zone__thumb" v-if="!showDropZone" :src="img" />

      <input
        id="img-input"
        type="file"
        name="file"
        :accept="acceptExtensions"
        @change="showSelectedImage"
        class="drop-zone__input"
      />
    </div>
    <label id="upload-label">{{ uploadLbl }}</label>
    <div>
      <button
        class="second-action-button action-btn"
        v-if="!btn_disabled"
        @click="selectImage"
      >
        {{ prediction_label }}
      </button>
    </div>
  </div>
</template>

<script>
import * as utils from "../../utils/utils.js";

export default {
  name: "ImageClassification",
  props: {
    endpoint: String
  },
  data() {
    return {
      acceptExtensions: utils.imageClassificationExtensions,
      img: "",
      prediction: "",
      confidence: "",
      force_disabled: false,
      prediction_label: utils.classify_label,
      show_form: false,
      uploadLbl: ""
    };
  },
  computed: {
    btn_disabled: function() {
      if (this.force_disabled) {
        return true;
      }

      return !this.img;
    },
    showDropZone: function() {
      return !this.img;
    }
  },
  methods: {
    showSelectedImage: function(e) {
      const imgFile = e.target.files[0];
      this.uploadLbl = imgFile.name;

      this.img = URL.createObjectURL(imgFile);

      this.predict();
    },
    dragover: function(e) {
      e.preventDefault();

      let dropzoneEl = document.querySelector(".drop-zone");
      dropzoneEl.classList.add("drop-zone--over");
    },
    enddrag: function() {
      let dropzoneEl = document.querySelector(".drop-zone");
      dropzoneEl.classList.remove("drop-zone--over");
    },
    drop: function(e) {
      e.preventDefault();

      if (e.dataTransfer.files.length) {
        let inputEl = utils.el("img-input");
        inputEl.files = e.dataTransfer.files;
        const imgFile = e.dataTransfer.files[0];
        this.img = URL.createObjectURL(imgFile);
      }

      let dropzoneEl = document.querySelector(".drop-zone");
      dropzoneEl.classList.remove("drop-zone--over");
      this.predict();
    },
    selectImage: function() {
      utils.el("img-input").click();
    },

    predict: function() {
      this.prediction = "";
      this.confidence = "";
      this.force_disabled = true;
      this.prediction_label = utils.classification_in_progress;

      let inputEl = utils.el("img-input");
      var imgFile = inputEl.files[0];
      var formData = new FormData();

      formData.append("file", imgFile);

      this.$http
        .post(this.endpoint, formData)
        .then(res => {
          this.prediction_label = utils.classify_another_label;
          this.prediction = res.data.prediction;
          this.confidence = res.data.confidence;
          this.force_disabled = false;
          this.show_form = true;
        })
        .catch(() => {
          this.force_disabled = false;
          this.prediction_label = utils.classify_another_label;
        });
    }
  }
};
</script>

<style scoped lang="scss">
.drop-zone__thumb {
  width: 50%;
}

form {
  color: #666;
  font-size: 18px;

  label {
    font-weight: 600;
  }
}
</style>
