<template>
  <div :class="top_class">
    <label class="checkbox-form-label" v-if="title" :for="input_id">{{
      title
    }}</label>
    <div class="form-group">
      <input
        :id="input_id"
        type="checkbox"
        v-model="entity[property]"
        :disabled="disabled"
      />
      <div class="d-flex align-items-center">
        <label
          :for="input_id"
          class="fas fa-2x"
          :class="[
            String(entity[property]) === 'true'
              ? 'fa-toggle-on'
              : 'fa-toggle-off',
            disabled ? 'fifty' : 'blue'
          ]"
        ></label>
        <label class="pl-3" :for="input_id">{{ text }}</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomCheckbox",
  props: {
    title: String,
    text: String,
    input_id: {
      type: String,
      required: true
    },
    entity: {
      type: Object,
      required: true
    },
    property: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    top_class: String
  }
};
</script>

<style scoped>
input[type="checkbox"] {
  display: none;
}

.fifty {
  opacity: 0.5;
}

.checkbox-form-label {
  font-size: 17px;
  color: #4285f4;
}
</style>
