import { render, staticRenderFns } from "./TextClassification.vue?vue&type=template&id=147c3ac5&scoped=true&"
import script from "./TextClassification.vue?vue&type=script&lang=js&"
export * from "./TextClassification.vue?vue&type=script&lang=js&"
import style0 from "./TextClassification.vue?vue&type=style&index=0&id=147c3ac5&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "147c3ac5",
  null
  
)

export default component.exports