<template>
  <div id="app">
    <div
      id="detail"
      :class="[{ collapsed: collapsed }, { onmobile: isOnMobile }]"
    >
      <router-view />
    </div>
    <sidebar-menu
      id="sidebar-menu"
      :menu="menu"
      :collapsed="collapsed"
      :theme="selectedTheme"
      :show-one-child="true"
      :visible="visible"
      @toggle-collapse="onToggleCollapse"
      @item-click="onItemClick"
    />
    <div
      v-if="isOnMobile && !collapsed"
      class="sidebar-overlay"
      @click="collapsed = true"
    />
  </div>
</template>

<script>
import Vue from "vue";

import * as utils from "./utils/utils.js";

const defaultMenu = [
  {
    header: true,
    title: "Models",
    hiddenOnCollapse: true
  },
  {
    href: "/",
    title: "All",
    icon: "fas fa-list",
    id: "all"
  },
  {
    href: "/?filter=public",
    title: "Public",
    icon: "fas fa-globe",
    id: "public"
  },
  {
    href: "/?filter=owned",
    title: "My models",
    icon: "fas fa-user",
    id: "owned"
  },
  {
    href: "/?filter=shared",
    title: "Shared with me",
    icon: "fas fa-share",
    id: "shared"
  },
  {
    header: true,
    title: "Datasets",
    hiddenOnCollapse: true,
    class: "menu-header-top"
  },
  {
    href: "/datasets",
    title: "All",
    icon: "fas fa-list",
    id: "all"
  },
  {
    href: "/datasets?filter=public",
    title: "Public",
    icon: "fas fa-globe",
    id: "public"
  },
  {
    href: "/datasets?filter=owned",
    title: "My datasets",
    icon: "fas fa-database",
    id: "owned"
  },
  {
    href: "/datasets?filter=shared",
    title: "Shared with me",
    icon: "fas fa-share",
    id: "shared"
  },
  {
    header: true,
    title: "Jobs",
    hiddenOnCollapse: true,
    class: "menu-header-top"
  },
  {
    href: "/jobs",
    title: "My jobs",
    icon: "fas fa-terminal",
    disabled: false
  },
  {
    header: true,
    title: "Other",
    hiddenOnCollapse: true,
    class: "menu-header-top"
  },
  {
    title: "Log out",
    icon: "fas fa-sign-out-alt",
    id: "logout"
  }
];

var visible = true;

// Menu adapted from: https://github.com/yaminncco/vue-sidebar-menu
export default {
  name: "App",
  data() {
    return {
      menu: defaultMenu,
      collapsed: false,
      hideToggle: true,
      visible: visible,
      themes: [
        {
          name: "Default theme",
          input: ""
        },
        {
          name: "White theme",
          input: "white-theme"
        }
      ],
      selectedTheme: "white-theme",
      isOnMobile: false
    };
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
    this.$root.$on("toggle_menu", val => {
      this.visible = val;
    });

    var key = localStorage.getItem("apikey");

    if (key == "") {
      this.visible = false;
    }
  },
  methods: {
    onToggleCollapse(collapsed) {
      this.collapsed = collapsed;
    },
    onItemClick(event, item) {
      if (item.id == "logout") {
        this.logout();
      }
    },
    onResize() {
      if (window.innerWidth <= 767) {
        this.isOnMobile = true;
        this.collapsed = true;
      } else {
        this.isOnMobile = false;
        this.collapsed = false;
      }
    },
    logout() {
      localStorage.setItem("user_id", "");
      localStorage.setItem("apikey", "");
      localStorage.setItem("username", "");

      utils.clearUser();

      Vue.http.headers.common["Authorization"] = "";

      this.$router.push("/login");
      this.visible = false;
    }
  }
};
</script>

<style>
#app {
  text-align: center;
  color: #2c3e50;
  height: 100%;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
