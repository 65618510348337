<template>
  <b-modal id="simple-modal" :title="title" @ok="ok" @hidden="hidden">
    Are you sure you want to delete:
    <span class="font-weight-bold">"{{ name }}"</span>
  </b-modal>
</template>

<script>
// import * as utils from "../utils/utils.js"

export default {
  name: "SimpleModal",
  props: {
    title: String,
    name: String,
    ok: Function,
    hidden: Function
  }
};
</script>
