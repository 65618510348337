<template>
  <div>
    <!-- Side menu -->
    <div
      id="sidemenu"
      class="v-sidebar-menu vsm_expanded vsm_white-theme"
      style="max-width: 350px; display: block"
    >
      <div>
        <label class="font-weight-bold">Manage predictions</label>
        <multiselect
          v-model="selectedModelVersions"
          :options="modelVersions"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Select model versions"
          label="name"
          :custom-label="customModelVersionLabel"
          :show-labels="false"
          track-by="version_number"
          :preselect-first="true"
        >
          <template slot="selection" slot-scope="{ values, search, isOpen }"
            ><span
              class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen"
              >{{ getSelectionLabel(values) }}</span
            ></template
          >
        </multiselect>
      </div>
      <div>
        <div>
          <button
            @click="toggleAllCurrent()"
            class="mt-4 p-2 btn-primary w-100"
          >
            {{ toggleAllLabel }}
          </button>
        </div>
      </div>
      <div id="inference-footer">
        <div class="flex">
          <b-form-group label="Show Hidden:">
            <a v-on:click.stop @click="toggleShowHidden()" class="btn">
              <i
                class="fas fa-2x"
                :class="showHidden ? 'fa-toggle-on' : 'fa-toggle-off'"
              ></i>
            </a>
          </b-form-group>
        </div>
        <div class="flex">
          <b-form-group label="Show already added predictions:">
            <a v-on:click.stop @click="toggleAlreadyAdded()" class="btn">
              <i
                class="fas fa-2x"
                :class="includeAlreadyAdded ? 'fa-toggle-on' : 'fa-toggle-off'"
              ></i>
            </a>
          </b-form-group>
        </div>
        <b-form-group label="Predictions per page:" label-for="page-size-input">
          <b-form-input
            id="page-size-input"
            v-model="pageSize"
            type="number"
            @blur="leavePageSizeInput"
          />
        </b-form-group>
      </div>
    </div>
    <b-modal
      id="manage-inference"
      ref="modal"
      title="Add predictions"
      size="lg"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleManageInferenceOk"
      :ok-disabled="modalOkDisabled()"
    >
      <form ref="manageInferenceForm">
        <div class="form-group has-feedback mt-5">
          <multiselect
            v-model="selectedDataset"
            :options="datasets"
            :multiple="false"
            :close-on-select="true"
            :preseve-search="true"
            :show-labels="false"
            label="name"
            placeholder="Find a dataset..."
            @select="selectDataset"
          >
          </multiselect>
          <label class="form-label auto-adjust-form-label"
            >Select dataset</label
          >
        </div>
        <div class="form-group has-feedback mt-5">
          <multiselect
            v-model="selectedVersion"
            :options="datasetVersions"
            :multiple="false"
            :close-on-select="true"
            :preseve-search="true"
            :show-labels="false"
            label="name"
            placeholder="Find a dataset version..."
            @select="selectDatasetVersion"
          >
          </multiselect>
          <label class="form-label auto-adjust-form-label"
            >Select dataset version</label
          >
        </div>

        <div class="form-group has-feedback mt-5">
          <multiselect
            v-model="selectedSplit"
            :options="splits"
            :multiple="false"
            :close-on-select="true"
            :preseve-search="true"
            :show-labels="false"
            label="name"
            placeholder="Find a dataset split..."
            @select="selectSplit"
          >
          </multiselect>
          <label class="form-label auto-adjust-form-label"
            >Select dataset split</label
          >
        </div>
        <custom-checkbox
          title="Keep labels"
          :entity="this"
          property="keepAnnotations"
          text="Add the predicted labels as annotations for every dataset item."
          input_id="keep-labels-input"
          top_class="px-3"
        />
      </form>
    </b-modal>

    <Header
      :entity="model"
      :action="manageInference"
      :actionLabel="manageInferenceLabel"
      :showLogo="true"
      :url="`/#/models/${model.id}/edit`"
      title="Back to model edit"
    />

    <div
      class="container-fluid upload overview mt-5"
      v-show="inferences.length"
      v-cloak
    >
      <div class="overview pl-2 pt-1">
        <div class="gallery" v-if="selectedInferenceIndex == -1">
          <div
            v-if="showPreviousPage"
            @click="changePage(false)"
            class="prev-img"
          >
            <i class="fas fa-chevron-left"></i>
          </div>
          <div
            v-for="(inference, index) in inferences"
            class="gallery-panel"
            :key="inference.id"
            @click="editInference(inference, index)"
          >
            <div
              v-if="
                model.kind == 'text_classification' ||
                  model.kind == 'ner' ||
                  model.kind == 'structured'
              "
            >
              {{ inference.name.substring(0, 200) }}
            </div>
            <div
              v-else-if="model.kind == 'speech_to_text'"
              style="margin: 0 auto"
            >
              <audio controls>
                <source :src="getImgUrl(inference)" />
              </audio>
            </div>
            <div v-else>
              <img :src="getImgUrl(inference)" />
            </div>
            <div class="overlay">
              <div class="flex left">
                <div class="vertical">
                  <div class="prediction">
                    {{ getPredictionLabel(inference) }}
                  </div>
                  <div class="description">
                    {{ getConfidenceLabel(inference) }}
                  </div>
                </div>
                <div class="flex">
                  <div class="vertical">
                    <a
                      v-on:click.stop
                      @click="toggleInference(inference, index)"
                      class="btn"
                    >
                      <i
                        class="fas"
                        :class="
                          inference.selected ? 'fa-toggle-on' : 'fa-toggle-off'
                        "
                      ></i>
                    </a>
                    <a
                      v-on:click.stop
                      @click="toggleHide(inference)"
                      class="btn"
                    >
                      <i
                        class="far"
                        :class="!inference.hidden ? 'fa-eye' : 'fa-eye-slash'"
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="showNextPage" @click="changePage(true)" class="next-img">
            <i class="fas fa-chevron-right"></i>
          </div>
        </div>
        <div class="image-detail" v-else>
          <form id="img-form" ref="imageform">
            <div id="img-container">
              <a
                v-if="showPreviousImage"
                @click="changeImage(false)"
                class="prev-img"
              >
                <i class="fas fa-chevron-left"></i>
              </a>
              <div
                v-if="
                  model.kind == 'text_classification' ||
                    model.kind == 'ner' ||
                    model.kind == 'structured'
                "
              >
                <div id="text-item">{{ itemText }}</div>
              </div>
              <div v-else-if="model.kind == 'speech_to_text'">
                <audio controls class="drop-zone__thumb" v-if="!showDropZone">
                  <source :src="getImgUrl(selectedInference)" />
                </audio>
              </div>
              <div v-else>
                <img
                  ref="annotationImage"
                  class="annotation-image"
                  :src="getImgUrl(selectedInference)"
                  @load="onLoadImg"
                />
                <canvas
                  id="canvas"
                  v-if="showCanvas(selectedInference)"
                  :width="canvasWidth"
                  :height="canvasHeight"
                />
              </div>
              <a @click="handleItemEditOk" class="close-image-detail">
                <i class="fas fa-times"></i>
              </a>
              <a
                v-if="showNextImage"
                @click="changeImage(true)"
                class="next-img"
              >
                <i class="fas fa-chevron-right"></i>
              </a>
            </div>
            <div id="label-container">
              <div class="vertical">
                <a
                  v-on:click.stop
                  @click="
                    toggleInference(selectedInference, selectedInferenceIndex)
                  "
                  class="btn"
                >
                  <i
                    class="fas"
                    :class="
                      selectedInference.selected
                        ? 'fa-toggle-on'
                        : 'fa-toggle-off'
                    "
                  ></i>
                </a>
                <a
                  v-on:click.stop
                  @click="toggleHide(selectedInference)"
                  class="btn"
                >
                  <i
                    class="far"
                    :class="
                      !selectedInference.hidden ? 'fa-eye' : 'fa-eye-slash'
                    "
                  ></i>
                </a>
              </div>
              <div v-if="showCanvas(selectedInference)">
                <div
                  v-for="inference in selectedInference.inference_items"
                  :key="inference.id"
                  class="annotation"
                  @mouseenter="hoverOverInference(inference)"
                  @mouseleave="hoverOutInference()"
                >
                  <i
                    :style="'color:' + getLabelColor(inference)"
                    class="fa fa-square-full"
                  />
                  {{ inference.prediction }}
                </div>
              </div>
              <div v-else>
                <div>
                  {{ selectedInference.prediction }}
                </div>
                <div>
                  {{ selectedInference.confidence }}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as utils from "../utils/utils.js";

let selectAllLabel = "Select all predictions";
let clearAllLabel = "Clear selection";

export default {
  data: function() {
    return {
      model: {},
      modelVersions: [],
      selectedModelVersions: [],
      datasets: [],
      datasetVersions: [],
      splits: [],
      inferences: [],
      selectedInferences: new Set(),
      datasetSearch: "",
      versionSearch: "",
      splitSearch: "",
      nameState: null,
      selectedDataset: {},
      selectedVersion: {},
      selectedSplit: {},
      keepAnnotations: true,
      manageInferenceLabel: "Add to dataset",
      pageSize: 25,
      pageCount: 0,
      inferenceCount: 0,
      selectedInferenceIndex: -1,
      selectedInference: {},
      includeAlreadyAdded: false,
      canvasHeight: 150,
      canvasWidth: 300,
      config: undefined,
      hoverInference: undefined,
      showHidden: false,
      toggleAllLabel: selectAllLabel,
      itemText: ""
    };
  },
  computed: {
    showPreviousPage: function() {
      return this.pageCount > 0;
    },
    showNextPage: function() {
      return this.inferenceCount > this.pageSize * (this.pageCount + 1);
    },
    showPreviousImage: function() {
      if (this.pageCount == 0 && this.selectedInferenceIndex == 0) {
        return false;
      }

      return true;
    },
    showNextImage: function() {
      return (
        this.inferenceCount >
        this.pageSize * this.pageCount + this.selectedInferenceIndex + 1
      );
    }
  },
  watch: {
    selectedModelVersions: function() {
      this.getInferences();
    }
  },
  methods: {
    getModel: function() {
      this.$http
        .get(`${utils.modelApi}/${this.$route.params.id}`)
        .then(response => {
          this.model = response.data;
          if (
            this.model.user_id == utils.user_id() ||
            this.model.shared_with_me
          ) {
            if (this.model.config) {
              this.config = JSON.parse(this.model.config);
            }
            this.getModelVersions();
          } else {
            this.$router.push("/");
          }
        })
        .catch(err => {
          if (err.status == this.utils.http_status_not_found) {
            this.$router.push({
              name: "NotFound"
            });
          } else {
            this.$router.push({
              name: "Login",
              query: { redirect: this.$route.fullPath }
            });
          }
        });
    },
    getModelVersions: function() {
      this.$http
        .get(`${utils.modelApi}/${this.model.id}/versions`)
        .then(res => {
          this.modelVersions = res.data;
        });
    },
    getInferences: function() {
      var url = `${utils.modelApi}/${this.$route.params.id}/inferences?pageCount=${this.pageCount}&pageSize=${this.pageSize}&includeAlreadyAdded=${this.includeAlreadyAdded}&showHidden=${this.showHidden}`;

      if (this.selectedModelVersions && this.selectedModelVersions.length > 0) {
        var modelVersionId = "";

        this.selectedModelVersions.forEach(mv => {
          modelVersionId += mv.id + ",";
        });

        if (modelVersionId.endsWith(",")) {
          modelVersionId = modelVersionId.slice(0, -1);
        }

        url += `&modelVersionIds=${modelVersionId}`;
      }

      this.$http.get(url).then(response => {
        for (var i = 0; i < response.data.length; i++) {
          response.data[i].selected = false;
        }
        this.inferences = response.data;
        this.getInferenceStats();
      });
    },
    getInferenceStats: function() {
      var url = `${utils.modelApi}/${this.$route.params.id}/stats?includeAlreadyAdded=${this.includeAlreadyAdded}&showHidden=${this.showHidden}`;

      if (this.selectedModelVersions && this.selectedModelVersions.length > 0) {
        var modelVersionId = "";

        this.selectedModelVersions.forEach(mv => {
          modelVersionId += mv.id + ",";
        });

        if (modelVersionId.endsWith(",")) {
          modelVersionId = modelVersionId.slice(0, -1);
        }

        url += `&modelVersionIds=${modelVersionId}`;
      }

      this.$http.get(url).then(response => {
        this.inferenceCount = response.body;
      });
    },
    updateInference: function(inference) {
      var url = `${utils.inferencesApi}/${inference.id}`;

      this.$http.put(url, inference);
    },
    getImgUrl: function(inference) {
      return `${utils.host}/inferences/${inference.id}/download`;
    },
    manageInference: function() {
      if (this.model.dataset_version_id) {
        this.$http
          .get(`${utils.host}/datasetversions/${this.model.dataset_version_id}`)
          .then(res => {
            let model_dataset = this.datasets.find(d => {
              return d.id == res.data.dataset_id;
            });
            this.selectDataset(model_dataset);
            this.$bvModal.show("manage-inference");
          });
      } else {
        this.$bvModal.show("manage-inference");
      }
    },
    getDatasets: function() {
      this.$http.get(utils.datasetsApi).then(response => {
        this.datasets = response.data;
      });
    },
    getDatasetVersions: function() {
      this.$http
        .get(`${utils.datasetsApi}/${this.selectedDataset.id}`)
        .then(response => {
          this.datasetVersions = response.data.versions;
        });
    },
    getSplits: function() {
      this.splits = this.selectedVersion.splits;
    },
    addInferencesCb: function() {
      this.$bvModal.hide("manage-inference");
      this.getInferences();
    },
    toggleInference: function(inference, index) {
      if (this.selectedInferences.has(inference)) {
        this.selectedInferences.delete(inference);
      } else {
        this.selectedInferences.add(inference);
      }

      this.setToggleLabel();

      this.inferences[index].selected = !this.inferences[index].selected;
    },
    clearSelectedInferences: function() {
      this.selectedInferences.forEach(i => {
        i.selected = false;
      });

      this.selectedInferences = new Set();

      this.setToggleLabel();
    },
    toggleAllCurrent: function() {
      var selectedInferenceCount = this.selectedInferences.size;

      if (
        selectedInferenceCount == this.inferences.length &&
        selectedInferenceCount > 0
      ) {
        this.clearSelectedInferences();
      } else {
        this.inferences.forEach(inf => {
          inf.selected = true;
          this.selectedInferences.add(inf);
        });
      }

      this.setToggleLabel();
    },
    setToggleLabel: function() {
      let selectedInferencesSize = this.selectedInferences.size;
      if (
        selectedInferencesSize == this.inferences.length &&
        selectedInferencesSize > 0
      ) {
        this.toggleAllLabel = clearAllLabel;
      } else {
        this.toggleAllLabel = selectAllLabel;
      }
    },
    toggleHide: function(inference) {
      inference.hidden = !inference.hidden;
      this.updateInference(inference);
    },
    handleManageInferenceOk: function() {
      this.addInferences(this.addInferencesCb);
    },
    addInferences: function(cb) {
      var url = `${utils.datasetsApi}/${this.selectedDataset.id}/versions/${this.selectedVersion.id}/splits/${this.selectedSplit.id}/add_inferences`;

      var obj = {
        keep_annotations: this.keepAnnotations,
        inferences: Array.from(this.selectedInferences)
      };

      this.$http.post(url, obj).then(() => {
        if (cb) {
          cb();
        }
      });
    },
    selectDataset: function(dataset) {
      this.selectedDataset = dataset;
      this.selectedVersion = {};
      this.selectedSplit = {};
      this.getDatasetVersions();
    },
    selectDatasetVersion: function(version) {
      this.selectedVersion = version;
      this.selectedSplit = {};
      this.getSplits();
    },
    selectSplit: function(split) {
      this.selectedSplit = split;
    },
    resetModal: function() {
      this.selectedDataset = {};
      this.selectedVersion = {};
      this.selectedSplit = {};
    },
    customModelVersionLabel: function(version) {
      return `${version.version} - ${version.name} - ${version.version_number}`;
    },
    leavePageSizeInput: function() {
      this.pageCount = 0;
      this.getInferences();
    },
    toggleAlreadyAdded: function() {
      this.includeAlreadyAdded = !this.includeAlreadyAdded;
      this.pageCount = 0;
      this.getInferences();
    },
    toggleShowHidden: function() {
      this.showHidden = !this.showHidden;
      this.pageCount = 0;
      this.getInferences();
    },
    getPredictionLabel: function(inference) {
      var inferenceItemCount = inference["inference_items"].length;
      if (inferenceItemCount > 0) {
        return `${inferenceItemCount} prediction(s)`;
      }

      return inference.prediction;
    },
    getConfidenceLabel: function(inference) {
      var inferenceItemCount = inference["inference_items"].length;
      if (inferenceItemCount > 0) {
        return "";
      }

      return inference.confidence;
    },
    getSelectionLabel: function(values) {
      var valueCount = values.length;
      if (valueCount > 1) {
        return `${valueCount} versions selected.`;
      }

      return this.customModelVersionLabel(values[0]);
    },
    editInference: function(inference, index) {
      this.selectedInference = inference;
      this.selectedInferenceIndex = index;
      this.itemText = inference.name;

      if (this.model.kind == "ner") {
        setTimeout(this.draw, 1);
      }
    },
    onLoadImg: function() {
      if (
        this.selectedInference &&
        this.selectedInference["inference_items"].length > 0
      ) {
        this.initCanvas();
        this.draw(false, true);
      }
    },
    initCanvas: function() {
      this.canvas = document.getElementById("canvas");

      if (this.canvas) {
        this.ctx = this.canvas.getContext("2d");

        var canvasOffset = this.canvas.getBoundingClientRect();

        this.canvasHeight = canvasOffset.height;
        this.canvasWidth = canvasOffset.width;

        this.offsetX = canvasOffset.left;
        this.offsetY = canvasOffset.top;

        // If we draw directly, the canvasHeight/canvasWidth has is not yet updated in the canvas
        setTimeout(this.draw, 1);
      }
    },
    handleItemEditOk: function() {
      this.clearSelectedInference();
    },
    setNextInferenceImage: function(index) {
      if (index != undefined) {
        this.selectedInferenceIndex = index;
      }

      this.selectedInference = this.inferences[this.selectedInferenceIndex];
    },
    changeImage: function(forward) {
      let samePage = true;

      if (forward) {
        if (this.selectedInferenceIndex + 1 < this.pageSize) {
          this.selectedInferenceIndex += 1;
        } else {
          samePage = false;
        }
      } else {
        if (this.selectedInferenceIndex > 0) {
          this.selectedInferenceIndex -= 1;
        } else {
          samePage = false;
        }
      }

      if (samePage) {
        this.setNextInferenceImage();
      } else {
        this.changePage(forward, this.setNextInferenceImage, 0);
      }
    },
    changePage: function(forward, cb, index) {
      if (forward) {
        this.pageCount += 1;
      } else {
        this.pageCount -= 1;
      }

      if (this.pageCount < 0) {
        this.pageCount = 0;
      }

      this.getInferences(cb, index);
    },
    clearSelectedInference: function() {
      this.selectedInference = {};
      this.selectedInferenceIndex = -1;
    },
    showCanvas: function(inference) {
      return inference["inference_items"].length > 0;
    },
    getLabelColor: function(inference) {
      if (this.config) {
        return this.config.colors[inference.prediction];
      }
      return "#0A8FCF";
    },
    convertPredictionToActual(coords) {
      var split = coords.split(" ");

      var x_min = parseFloat(split[0]);
      var y_min = parseFloat(split[1]);
      var x_max = parseFloat(split[2]);
      var y_max = parseFloat(split[3]);

      return {
        x: x_min * this.canvasWidth,
        y: y_min * this.canvasHeight,
        w: (x_max - x_min) * this.canvasWidth,
        h: (y_max - y_min) * this.canvasHeight
      };
    },
    hoverOverInference: function(inference) {
      this.hoverInference = inference;
      this.draw();
    },
    hoverOutInference: function() {
      this.hoverInference = undefined;
      this.draw();
    },
    modalOkDisabled: function() {
      if (
        this.selectedDataset &&
        this.selectedDataset.id &&
        this.selectedVersion &&
        this.selectedVersion.id &&
        this.selectedSplit &&
        this.selectedSplit.id
      ) {
        return false;
      }
      return true;
    },
    draw: function() {
      if (this.model.kind == "ner") {
        if (this.selectedInference && this.selectedInference.inference_items) {
          var annotations = this.selectedInference.inference_items.map(i => {
            return {
              coordinates: i.coordinates,
              label_id: i.prediction
            };
          });

          utils.highlight(
            "text-item",
            this.itemText,
            {},
            annotations,
            this.config.colors,
            utils.default_selection_color,
            this.hoverInference
          );
        }
        return;
      }

      this.ctx.strokeStyle = "black";
      this.ctx.setLineDash([]);
      this.ctx.globalAlpha = 1;
      this.ctx.lineWidth = 1;

      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

      if (this.selectedInference && this.selectedInference.inference_items) {
        this.selectedInference.inference_items.forEach(i => {
          if (i.coordinates) {
            this.ctx.setLineDash([]);
            this.ctx.globalAlpha = 1;
            var coordsToDraw = this.convertPredictionToActual(i.coordinates);
            this.ctx.strokeStyle = this.getLabelColor(i);
            this.ctx.lineWidth = 1;

            if (this.hoverInference && this.hoverInference.id == i.id) {
              this.ctx.setLineDash([4, 4]);
              this.ctx.lineWidth = 2;
            }

            this.ctx.strokeRect(
              Math.round(coordsToDraw.x) + 0.5,
              Math.round(coordsToDraw.y) + 0.5,
              Math.round(coordsToDraw.w),
              Math.round(coordsToDraw.h)
            );

            let globalAlpha = this.globalAlpha;

            this.ctx.globalAlpha = 0.3;
            this.ctx.fillStyle = this.ctx.strokeStyle;

            this.ctx.fillRect(
              Math.round(coordsToDraw.x) + 1,
              Math.round(coordsToDraw.y) + 1,
              Math.round(coordsToDraw.w) - 1,
              Math.round(coordsToDraw.h) - 1
            );

            this.ctx.globalAlpha = globalAlpha;
          }
        });
      }
    }
  },
  mounted() {
    this.getInferences();
    this.getModel();
    this.getDatasets();
    this.$ga.page("inferences-model");
    utils.showMenu(false, true, this);
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.multiselect__tags,
.multiselect__single,
.multiselect__input {
  background: #edf2f7;
}
</style>

<style scoped>
#label-container {
  position: absolute;
  display: inline-block;
  width: 20%;
  padding: 0 5px;
  overflow: scroll;
  height: 80%;
}

.label {
  font-size: 16px;
  padding: 8px 0;
}

.lbl-btn {
  margin-top: 2px;
  padding: 5px 4px 0 4px;
}

.card-body {
  padding: 0;
}

.no-display {
  display: none;
}

.label-area {
  min-height: 70%;
}

.label-list {
  max-height: 80%;
  overflow: scroll;
  margin-top: 10px;
}

.inference-mgmt {
  position: fixed;
  top: 0;
  left: 0;
  width: 350px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  z-index: 999;
  box-sizing: border-box;
  text-align: left;
  background-color: rgb(42, 42, 46);
  color: white;
  padding: 10px;
}

#inference-footer {
  position: fixed;
  bottom: 10px;
  left: 10px;
  width: 320px;
  margin-left: 10px;
  margin-right: 10px;
}

.selectedLabel {
  background-color: #bbb;
}

.row {
  margin-left: 0px;
  margin-right: 0px;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  grid-gap: 1rem;
  max-width: 100%;
  margin: 0.5rem auto;
  padding: 0 0.2rem;
}

.gallery-panel {
  position: relative;
  min-height: 250px;
}

.gallery-panel img {
  width: 100%;
  height: 15vw;
  object-fit: cover;
  border-radius: 0.15rem;
  display: block;
}

/* The overlay effect - lays on top of the container and over the image */
.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.35);
  /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: 0.5s ease;
  color: white;
  font-size: 10px;
  text-align: center;
  height: 25%;
}

.flex {
  display: flex;
}

.left {
  width: 100%;
  height: 100%;
}

.prediction {
  height: 70%;
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  padding-left: 5px;
}

.description {
  text-align: left;
  padding-left: 5px;
}

.vertical {
  display: block;
  width: 100%;
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selectedLabel {
  background-color: #bbb;
}

.row {
  margin-left: 0px;
  margin-right: 0px;
}

.annotation {
  padding: 8px;
}

.annotation-image {
  margin: 0 auto;
  display: block;
  width: 100%;
}

.annotation-delete {
  float: right;
  margin-right: 20px;
}

#img-form {
  text-align: left;
}

#img-container {
  position: relative;
  display: inline-block;
  width: 70%;
  height: 80%;
  white-space: break-spaces;
}

.prev-img,
.next-img,
.close-image-detail {
  width: 44px;
  height: 44px;
  position: absolute;
  z-index: 100;
  cursor: pointer;
  background-color: rgba(240, 240, 240, 0.5);
  font-weight: bolder;
  text-align: center;
  padding-top: 10px;
}

.close-image-detail {
  top: 0;
  right: 0;
}

.prev-img {
  top: 50%;
}

.next-img {
  right: 0px;
  top: 50%;
}

.col-sm {
  padding-left: 5px;
  padding-right: 5px;
}

canvas {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
</style>
