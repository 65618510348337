<template>
  <div>
    <div
      class="drop-zone mx-auto"
      @click="selectSound"
      @dragover="dragover"
      @dragend="enddrag"
      @dragleave="enddrag"
      @drop="drop"
    >
      <span v-if="showDropZone" class="drop-zone__prompt"
        >Drop your sound file here or click to upload</span
      >
      <audio
        id="audio"
        controls
        :src="sound"
        class="drop-zone__thumb"
        v-if="!showDropZone"
      ></audio>

      <input
        id="sound-input"
        type="file"
        name="file"
        :accept="acceptExtensions"
        @change="showSelectedSound"
        class="drop-zone__input"
      />
    </div>
    <label id="upload-label">{{ uploadLbl }}</label>
    <div>
      <button
        class="second-action-button action-btn"
        v-if="!btn_disabled"
        @click="selectSound"
      >
        {{ prediction_label }}
      </button>
      <span v-if="force_disabled"><i class="fas fa-spinner fa-pulse"></i></span>
    </div>
    <form class="w-25 mx-auto" v-show="show_form">
      <div class="">
        <label for="prediction">Transcription:</label>
        <span id="prediction">
          {{ prediction }}
        </span>
      </div>
    </form>
  </div>
</template>

<script>
import * as utils from "../../utils/utils.js";

export default {
  name: "SpeechToText",
  props: {
    endpoint: String
  },
  data() {
    return {
      acceptExtensions: utils.speechToTextExtensions,
      sound: "",
      prediction: "",
      confidence: "",
      force_disabled: false,
      prediction_label: utils.speech_to_text_label,
      show_form: false,
      uploadLbl: ""
    };
  },
  computed: {
    btn_disabled: function() {
      if (this.force_disabled) {
        return true;
      }

      return !this.sound;
    },
    showDropZone: function() {
      return !this.sound;
    }
  },
  methods: {
    showSelectedSound: function(e) {
      const soundFile = e.target.files[0];
      this.uploadLbl = soundFile.name;

      this.sound = URL.createObjectURL(soundFile);

      this.predict();
    },
    dragover: function(e) {
      e.preventDefault();

      let dropzoneEl = document.querySelector(".drop-zone");
      dropzoneEl.classList.add("drop-zone--over");
    },
    enddrag: function() {
      let dropzoneEl = document.querySelector(".drop-zone");
      dropzoneEl.classList.remove("drop-zone--over");
    },
    drop: function(e) {
      e.preventDefault();

      if (e.dataTransfer.files.length) {
        let inputEl = utils.el("sound-input");
        inputEl.files = e.dataTransfer.files;
        const soundFile = e.dataTransfer.files[0];
        this.sound = URL.createObjectURL(soundFile);
      }

      let dropzoneEl = document.querySelector(".drop-zone");
      dropzoneEl.classList.remove("drop-zone--over");
      this.predict();
    },
    selectSound: function() {
      utils.el("sound-input").click();
    },
    predict: function() {
      this.prediction = "";
      this.confidence = "";
      this.force_disabled = true;
      this.prediction_label = utils.transcribe_in_progress;

      let inputEl = utils.el("sound-input");
      var soundFile = inputEl.files[0];
      var formData = new FormData();

      formData.append("file", soundFile);

      this.$http
        .post(this.endpoint, formData)
        .then(res => {
          this.prediction_label = utils.transcribe_another;

          let pred = JSON.parse(res.body.prediction.replace(/'/g, '"'));
          this.prediction = pred["text"].replace(/"/g, "'");

          this.force_disabled = false;
          this.show_form = true;
        })
        .catch(() => {
          this.force_disabled = false;
          this.prediction_label = utils.classify_another_label;
        });
    }
  }
};
</script>

<style scoped lang="scss">
.drop-zone__thumb {
  width: 40%;
}

form {
  color: #666;
  font-size: 18px;
  margin-top: 20px;

  label {
    font-weight: 600;
  }
}
</style>
