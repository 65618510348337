<template>
  <div>
    <div class="text-container">
      <textarea
        id="textarea"
        type="text"
        rows="10"
        class="col-sm-10"
        v-model="chatOutput"
        :placeholder="utils.text_input_placeholder"
      />
    </div>
    <div>
      <div class="text-container">
        <textarea
          id="textaread-input"
          type="text"
          rows="2"
          class="col-sm-10"
          v-model="nlpInput"
        />
      </div>
      <button
        class="second-action-button action-btn"
        :disabled="btn_disabled"
        @click="predict"
      >
        {{ prediction_label }}
      </button>
    </div>
  </div>
</template>

<script>
import * as utils from "../../utils/utils.js";

export default {
  name: "LLM",
  props: {
    endpoint: String
  },
  data() {
    return {
      force_disabled: false,
      prediction_label: utils.chat_label,
      chatOutput: "",
      nlpInput: "Q: What are the countries in Europe? A: ",
      utils: utils,
      platform: ""
    };
  },
  computed: {
    btn_disabled: function() {
      if (this.force_disabled) {
        return true;
      }
      return this.nlpInput.length == 0;
    }
  },
  methods: {
    predict: function() {
      this.force_disabled = true;
      this.prediction_label = utils.chat_in_progress;

      var actualInput = this.nlpInput.trim();

      if (this.chatOutput.length > 0) {
        actualInput = `${this.chatOutput} ${actualInput}`;
      }

      var data = {
        input_text: actualInput
      };

      this.$http
        .post(`${this.endpoint}?platform=${this.platform}`, data)
        .then(res => {
          this.prediction_label = utils.chat_label;
          this.chatOutput = res.data.prediction;
          this.force_disabled = false;
        })
        .catch(() => {
          this.force_disabled = false;
          this.prediction_label = utils.generate_label;
        });
    }
  }
};
</script>

<style scoped lang="scss">
form {
  color: #666;
  font-size: 18px;

  label {
    font-weight: 600;
  }
}
</style>
