<template>
  <div>
    <section class="main">
      <Header
        :entity="headerEntity"
        :actionLabel="actionLabel"
        :action="goToApp"
        :showLogo="true"
      />
      <div class="container-fluid">
        <div class="reset-box">
          <div class="row bg-light mx-auto text-center reset">
            <div class="col p-5 d-flex justify-content-center">
              <form v-if="show_input_form" accept-charset="utf-8">
                <div class="form-group has-feedback">
                  <input
                    id="password-input"
                    class="form-control"
                    type="text"
                    v-model="login"
                    required="required"
                  />
                  <label
                    @click="utils.focus('password-input')"
                    class="form-label"
                    >E-mail or username</label
                  >
                </div>
                <div class="row">
                  <div class="col-xs-4 login-button">
                    <button
                      class="btn-primary action-btn reset-btn mx-auto"
                      @click="resetPassword"
                      :disabled="btn_disabled"
                    >
                      {{ reset_button_label }}
                    </button>
                  </div>
                </div>
              </form>
              <form v-else>
                <label v-if="error_msg" class="error">{{ error_msg }}</label>
                <div class="title text-left">Request received</div>
                <div>Please check the account email to continue.</div>
              </form>
            </div>
          </div>
          <hr />

          <div class="container-fluid">
            <div class="row">
              No account yet?
              <a class="more-link" href="/#/register"> Sign up</a>
            </div>
            <div class="row">
              Have an account? <a class="more-link" href="/#/login">Log in</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import * as utils from "../utils/utils.js";
export default {
  name: "ResetPasswordRequest",
  data: function() {
    return {
      headerEntity: { name: utils.request_password_label },
      actionLabel: "Go to app",
      login: "",
      error: "",
      error_msg: undefined,
      force_disable_btn: false,
      reset_button_label: utils.reset_password_label,
      utils: utils,
      show_input_form: true
    };
  },
  methods: {
    goToApp: function() {
      this.$router.push("/");
    },
    resetPassword: function() {
      this.error_msg = undefined;

      this.force_disable_btn = true;

      this.reset_button_label = this.utils.reset_requesting;

      var password_reset = {
        login: this.login
      };

      this.$http
        .post(utils.passwordResetApi, password_reset)
        .then(() => {
          this.show_input_form = false;
        })
        .catch(() => {
          this.reset_button_label = this.utils.reset_password_label;
          this.show_input_form = true;
        });
    }
  },
  mounted() {
    utils.showMenu(false, false, this);
    this.$ga.page("login");
  },
  computed: {
    btn_disabled: function() {
      if (this.force_disable_btn) {
        return this.force_disable_btn;
      }

      if (this.login.length == 0) {
        return true;
      }

      return false;
    }
  }
};
</script>

<style scoped>
.reset-btn {
  width: 180px;
}
</style>
