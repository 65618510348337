<template>
  <div>
    <div class="row">
      <div class="col-2" v-if="show_info"></div>
      <div :class="show_info ? 'col-8' : 'col'">
        <div
          class="drop-zone mx-auto"
          @click="selectImage"
          @dragover="dragover"
          @dragend="enddrag"
          @dragleave="enddrag"
          @drop="drop"
        >
          <span v-if="showDropZone" class="drop-zone__prompt"
            >Drop your image here or click to upload</span
          >
          <div
            id="img-container"
            v-bind:style="{
              height: visualHeight + 'px',
              width: visualWidth + 'px'
            }"
            v-if="!showDropZone"
          >
            <img
              id="img-to-predict"
              class="drop-zone__thumb coveredImage"
              :src="img"
              ref="imagePicked"
              @load="imgChosenChanged"
              v-bind:style="{
                height: visualHeight + 'px',
                width: visualWidth + 'px'
              }"
            />
            <canvas
              id="canvas"
              :height="visualHeight + 'px'"
              :width="visualWidth + 'px'"
            >
            </canvas>
          </div>
          <input
            id="img-input"
            type="file"
            name="file"
            :accept="acceptExtensions"
            @change="showSelectedImage"
            class="drop-zone__input"
          />
        </div>
        <div>
          <button
            class="second-action-button action-btn"
            v-if="!btn_disabled"
            @click="selectImage"
          >
            {{ prediction_label }}
          </button>
        </div>
      </div>
      <div class="col-2" v-if="show_info">
        <h2 class="">Info</h2>
        <ul v-if="config && config.colors">
          <li
            v-for="color_key in Object.keys(config.colors)"
            :key="color_key"
            class="text-left mb-3"
          >
            <i
              :style="'color: ' + config.colors[color_key]"
              class="fa fa-square-full"
            />

            {{ " " + color_key }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import * as utils from "../../utils/utils.js";

export default {
  name: "ObjectDetection",
  props: {
    endpoint: String,
    model_config: String
  },
  data() {
    return {
      acceptExtensions: utils.objectDetectionExtensions,
      img: "",
      force_disabled: false,
      prediction_label: utils.predict_another_label,
      visualHeight: window.innerHeight * 0.5,
      visualWidth: window.innerHeight * 0.5,
      config: { colors: {} },
      inference_items: [],
      canvas: {},
      ctx: {},
      show_info: true
    };
  },
  mounted() {
    if (this.model_config) {
      this.config = JSON.parse(this.model_config);
    }
  },
  computed: {
    btn_disabled: function() {
      if (this.force_disabled) {
        return true;
      }

      return !this.img;
    },
    showDropZone: function() {
      return !this.img;
    }
  },
  methods: {
    showSelectedImage: function(e) {
      const imgFile = e.target.files[0];

      this.img = URL.createObjectURL(imgFile);

      this.predict();
    },
    dragover: function(e) {
      e.preventDefault();

      let dropzoneEl = document.querySelector(".drop-zone");
      dropzoneEl.classList.add("drop-zone--over");
    },
    enddrag: function() {
      let dropzoneEl = document.querySelector(".drop-zone");
      dropzoneEl.classList.remove("drop-zone--over");
    },
    drop: function(e) {
      e.preventDefault();

      if (e.dataTransfer.files.length) {
        let inputEl = utils.el("img-input");
        inputEl.files = e.dataTransfer.files;
        const imgFile = e.dataTransfer.files[0];
        this.img = URL.createObjectURL(imgFile);
      }

      let dropzoneEl = document.querySelector(".drop-zone");
      dropzoneEl.classList.remove("drop-zone--over");
      this.predict();
    },
    selectImage: function() {
      utils.el("img-input").click();
    },
    imgChosenChanged: function() {
      var imgPicked = this.$refs.imagePicked;

      var ih = window.innerHeight * 0.5;

      this.visualHeight = Math.round(
        (imgPicked["naturalHeight"] * ih) / imgPicked["naturalHeight"]
      );
      this.visualWidth = Math.round(
        (imgPicked["naturalWidth"] * ih) / imgPicked["naturalHeight"]
      );
    },
    checkCanvas: function(clear) {
      this.canvas = utils.el("canvas");
      if (this.canvas) {
        this.ctx = this.canvas.getContext("2d");
        if (clear) {
          this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        }
      }
    },
    predict: function() {
      this.checkCanvas(true);
      this.force_disabled = true;
      this.prediction_label = utils.prediction_in_progress_label;

      let inputEl = utils.el("img-input");
      var imgFile = inputEl.files[0];
      var formData = new FormData();

      formData.append("file", imgFile);

      this.$http
        .post(this.endpoint, formData)
        .then(res => {
          this.force_disabled = false;
          this.inference_items = res.body.inference_items;

          if (this.inference_items.length > 0) {
            this.inference_items.forEach(i => {
              var split_coords = i.coordinates.split(" ");
              var coords = {
                x_min: split_coords[0],
                y_min: split_coords[1],
                x_max: split_coords[2],
                y_max: split_coords[3]
              };

              if (!this.config["colors"][i.prediction]) {
                this.config["colors"][i.prediction] = utils.random_color();
              }

              this.checkCanvas(false);
              this.ctx.strokeStyle = this.config["colors"][i.prediction];

              let x1 = Math.round(coords.x_min * this.visualWidth);
              let y1 = Math.round(coords.y_min * this.visualHeight);
              let x2 = Math.round(
                (coords.x_max - coords.x_min) * this.visualWidth
              );
              let y2 = Math.round(
                (coords.y_max - coords.y_min) * this.visualHeight
              );

              this.ctx.strokeRect(x1 + 0.5, y1 + 0.5, x2, y2);
            });
          }
          this.prediction_label = utils.predict_another_label;
        })
        .catch(() => {
          this.force_disabled = false;
          this.prediction_label = utils.predict_another_label;
        });
    }
  }
};
</script>
