<template>
  <div>
    <div class="row">
      <div class="col-3" v-if="show_info"></div>
      <div :class="show_info ? 'col-6' : 'col'">
        <div class="text-container">
          <div id="backdrop" class="backdrop">
            <div id="highlights" class="highlights"></div>
          </div>
          <textarea
            id="textarea"
            type="text"
            rows="10"
            v-model="nlpInput"
            :placeholder="utils.text_input_placeholder"
            @scroll="handleScroll"
          />
        </div>
      </div>
      <div class="col-3" v-if="show_info">
        <h2 class="">Info</h2>
        <ul v-if="config && config.colors">
          <li
            v-for="color_key in Object.keys(config.colors)"
            :key="color_key"
            class="text-left mb-3"
          >
            <i
              :style="'color: ' + config.colors[color_key]"
              class="fa fa-square-full"
            />

            {{ " " + color_key }}
          </li>
        </ul>
      </div>
    </div>
    <div class="mt-3">
      <button
        class="second-action-button action-btn"
        :disabled="btn_disabled"
        @click="predict"
      >
        {{ prediction_label }}
      </button>
    </div>
  </div>
</template>

<script>
import * as utils from "../../utils/utils.js";

export default {
  name: "NamedEntityRecognition",
  props: {
    endpoint: String,
    model_config: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      force_disabled: false,
      prediction_label: utils.extract_label,
      nlpInput: "",
      utils: utils,
      config: { colors: {} },
      show_info: true
    };
  },
  mounted() {
    if (this.model_config) {
      this.processConfig(this.model_config);
    }
  },
  computed: {
    btn_disabled: function() {
      if (this.force_disabled) {
        return true;
      }
      return this.nlpInput.length == 0;
    }
  },
  watch: {
    model_config: function(newVal) {
      this.processConfig(newVal);
    },
    nlpInput: function() {
      this.cleanHighlights();
    }
  },
  methods: {
    predict: function() {
      var textarea = utils.el("textarea");
      if (this.prediction_label == utils.extract_another_label) {
        this.cleanHighlights();
      }

      this.prediction = "";
      this.confidence = "";
      this.force_disabled = true;
      this.prediction_label = utils.extraction_in_progress_label;

      this.nlpInput = this.nlpInput.trim();

      var data = {
        input_text: this.nlpInput
      };

      if (textarea) {
        textarea.scrollTop = 0;
      }

      this.$http
        .post(this.endpoint, data)
        .then(res => {
          var annotations = res.data.inference_items.map(i => {
            if (!this.config["colors"][i.prediction]) {
              this.config["colors"][i.prediction] = utils.random_color();
            }

            return {
              coordinates: i.coordinates,
              label_id: i.prediction
            };
          });

          utils.highlight(
            "highlights",
            this.nlpInput,
            {},
            annotations,
            this.config.colors,
            utils.default_selection_color
          );

          self.handleScroll();
          this.prediction_label = utils.extract_another_label;
          this.force_disabled = false;
        })
        .catch(() => {
          this.force_disabled = false;
          this.prediction_label = utils.extract_another_label;
        });
    },
    processConfig: function(config) {
      if (config) {
        this.config = JSON.parse(config);
      }
    },
    handleScroll: function() {
      var textarea = utils.el("textarea");
      var backdrop = utils.el("backdrop");
      var scrollTop = textarea.scrollTop;
      backdrop.scrollTop = scrollTop;

      var scrollLeft = textarea.scrollLeft;
      backdrop.scrollLeft = scrollLeft;
    },
    cleanHighlights: function() {
      var hl = utils.el("highlights");
      hl.innerHTML = "";
    }
  }
};
</script>

<style scoped lang="scss">
textarea {
  margin: 0;
  border-radius: 0;
}

.text-container {
  display: block;
  margin: auto;
}

.text-container,
.backdrop,
textarea {
  width: 640px;
  height: 480px;
  text-align: left;
}

.backdrop {
  overflow: auto;
}

.highlights {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.highlights,
textarea {
  padding: 10px;
  font: 20px/28px "Open Sans", sans-serif;
  //letter-spacing: 1px;
}

.backdrop {
  position: absolute;
  z-index: 1;
  border: 2px solid transparent;
  background-color: #fff;
  overflow: auto;
  pointer-events: none;
  transition: transform 1s;
}

.highlights {
  white-space: pre-wrap;
  word-wrap: break-word;
}

textarea {
  display: block;
  position: absolute;
  z-index: 2;
  margin: 0;
  border: 2px solid #eee;
  border-radius: 0;
  color: #444;
  background-color: transparent;
  overflow: auto;
  resize: none;
  transition: transform 1s;
}

mark {
  border-radius: 3px;
  color: transparent;
  background-color: #b1d5e5;
}

textarea:focus,
button:focus {
  outline: none;
}
</style>
