<template>
  <div id="edit-view">
    <section class="main pt-3">
      <SimpleModal
        :title="deleteModalTitle"
        :name="deleteName"
        :ok="modalOk"
        :hidden="cancelDelete"
      >
      </SimpleModal>
      <Header
        :entity="job"
        entityLabel="Job:"
        :action="updateJob"
        :actionLabel="actionLabel"
      />
      <div class="container-fluid mt-5">
        <div class="overview pl-2 pt-1">
          <div class="row">
            <div class="col">
              <span class="title">General</span>
              <form class="mt-4">
                <div class="form-group has-feedback pt-3" v-if="job.id">
                  <input
                    class="form-control disabled-input sided-with-copy"
                    type="text"
                    v-model="job.id"
                    required
                    onkeypress="return false;"
                  />
                  <label class="form-label pt-1">Id</label>
                  <a
                    class="hover-larger pointer"
                    @click="copyToClipboard(job.id, 'job', 'Job id')"
                    @mouseout="resetCopyLabel()"
                    v-b-popover.hover.top="getCopyLabel('job')"
                    ><i class="fas fa-copy"
                  /></a>
                </div>
                <div class="form-group has-feedback">
                  <input
                    id="job-name-input"
                    class="form-control"
                    type="text"
                    v-model="job.name"
                    required
                  />
                  <label
                    @click="utils.focus('job-name-input')"
                    class="form-label"
                    >Name</label
                  >
                </div>
                <div class="form-group has-feedback mt-5">
                  <multiselect
                    id="status-select"
                    v-model="job.status"
                    :options="utils.supported_job_states"
                    :close-on-select="true"
                    :preserve-search="true"
                    placeholder="Update job status"
                    :show-labels="false"
                    :allow-empty="false"
                  >
                    <template
                      slot="selection"
                      slot-scope="{ values, search, isOpen }"
                      ><span
                        class="multiselect__single"
                        v-if="values.length &amp;&amp; !isOpen"
                        >{{ getSelectionLabel(values) }}</span
                      ></template
                    >
                  </multiselect>
                  <label class="form-label auto-adjust-form-label"
                    >Status</label
                  >
                </div>
                <div class="form-group has-feedback mt-5">
                  <input
                    id="job-status-message-input"
                    class="form-control"
                    type="text"
                    v-model="job.status_message"
                    required
                  />
                  <label
                    @click="utils.focus('job-status-message-input')"
                    class="form-label"
                    >Status message</label
                  >
                </div>
                <div class="form-group has-feedback mt-5">
                  <input
                    id="job-type-input"
                    class="form-control"
                    type="text"
                    v-model="job.job_type"
                    required
                  />
                  <label
                    @click="utils.focus('job-type-input')"
                    class="form-label"
                    >Type</label
                  >
                </div>
                <div class="form-group has-feedback mt-5">
                  <input
                    id="job-application-input"
                    class="form-control w90"
                    type="text"
                    v-model="application_name"
                    required
                    v-b-popover.hover.top="getJobProperty('application_id')"
                  />
                  <label
                    @click="utils.focus('job-application-input')"
                    class="form-label"
                    >Application</label
                  >
                  <a
                    v-if="job.application_id"
                    class="hover-larger pointer"
                    @click="
                      copyToClipboard(
                        job.application_id,
                        'application',
                        'Application id'
                      )
                    "
                    @mouseout="resetCopyLabel()"
                    v-b-popover.hover.top="getCopyLabel('application')"
                    ><i class="fas fa-copy"
                  /></a>
                </div>
                <div class="form-group has-feedback mt-5">
                  <input
                    id="agent-name-input"
                    class="form-control"
                    type="text"
                    v-model="job.agent_name"
                    required
                  />
                  <label
                    @click="utils.focus('agent-name-input')"
                    class="form-label"
                    >Agent name</label
                  >
                </div>
                <div class="form-group has-feedback mt-5">
                  <textarea
                    id="description-input"
                    class="form-control"
                    type="text"
                    v-model="job.description"
                  />
                  <label
                    @click="utils.focus('description-input')"
                    class="form-label"
                    >Description</label
                  >
                </div>
              </form>
            </div>
            <div class="col">
              <span class="title">Info</span>
              <form class="mt-4">
                <div class="form-group has-feedback mt-5">
                  <input
                    id="job-dataset-name-input"
                    class="form-control w90"
                    type="text"
                    v-model="dataset.name"
                    required
                    v-b-popover.hover.top="getJobProperty('dataset_id')"
                  />
                  <label
                    @click="utils.focus('job-dataset-name-input')"
                    class="form-label"
                    >Dataset</label
                  >
                  <a
                    v-if="job.dataset_id"
                    class="hover-larger pointer"
                    @click="
                      copyToClipboard(job.dataset_id, 'dataset', 'Dataset id')
                    "
                    @mouseout="resetCopyLabel()"
                    v-b-popover.hover.top="getCopyLabel('dataset')"
                    ><i class="fas fa-copy"
                  /></a>
                </div>
                <div class="form-group has-feedback mt-5">
                  <input
                    id="job-dataset-version-id-input"
                    class="form-control w90"
                    type="text"
                    v-model="dataset_version.name"
                    required
                    v-b-popover.hover.top="getJobProperty('dataset_version_id')"
                  />
                  <label
                    @click="utils.focus('job-dataset-version-id-input')"
                    class="form-label"
                    >Dataset version</label
                  >
                  <a
                    v-if="job.dataset_version_id"
                    class="hover-larger pointer"
                    @click="
                      copyToClipboard(
                        job.dataset_version_id,
                        'dataset_version',
                        'Dataset version id'
                      )
                    "
                    @mouseout="resetCopyLabel()"
                    v-b-popover.hover.top="getCopyLabel('dataset_version')"
                    ><i class="fas fa-copy"
                  /></a>
                </div>
                <div class="form-group has-feedback mt-5">
                  <input
                    id="job-model-id-input"
                    class="form-control w90"
                    type="text"
                    v-model="model.name"
                    required
                    v-b-popover.hover.top="getJobProperty('model_id')"
                  />
                  <label
                    @click="utils.focus('job-model-id-input')"
                    class="form-label"
                    >Model</label
                  >
                  <a
                    v-if="job.model_id"
                    class="hover-larger pointer"
                    @click="copyToClipboard(job.model_id, 'model', 'Model id')"
                    @mouseout="resetCopyLabel()"
                    v-b-popover.hover.top="getCopyLabel('model')"
                    ><i class="fas fa-copy"
                  /></a>
                </div>

                <div class="form-group has-feedback mt-5">
                  <input
                    id="job-model-version-id-input"
                    class="form-control w90"
                    type="text"
                    v-model="model_version.name"
                    required
                    v-b-popover.hover.top="getJobProperty('model_version_id')"
                  />
                  <label
                    @click="utils.focus('job-model-version-id-input')"
                    class="form-label"
                    >Model version</label
                  >
                  <a
                    v-if="job.model_version_id"
                    class="hover-larger pointer"
                    @click="
                      copyToClipboard(
                        job.model_version_id,
                        'model_version',
                        'Model version id'
                      )
                    "
                    @mouseout="resetCopyLabel()"
                    v-b-popover.hover.top="getCopyLabel('model_version')"
                    ><i class="fas fa-copy"
                  /></a>
                </div>
                <div class="form-group has-feedback mt-5">
                  <input
                    id="job-start-model-id-input"
                    class="form-control w90"
                    type="text"
                    v-model="start_model.name"
                    required
                    v-b-popover.hover.top="getJobProperty('start_model_id')"
                  />
                  <label
                    @click="utils.focus('job-start-model-id-input')"
                    class="form-label"
                    >Start model</label
                  >
                  <a
                    v-if="job.start_model_id"
                    class="hover-larger pointer"
                    @click="
                      copyToClipboard(
                        job.start_model_id,
                        'start_model',
                        'Start model id'
                      )
                    "
                    @mouseout="resetCopyLabel()"
                    v-b-popover.hover.top="getCopyLabel('start_model')"
                    ><i class="fas fa-copy"
                  /></a>
                </div>
                <div class="form-group has-feedback mt-5">
                  <input
                    id="job-start-model-id-input"
                    class="form-control w90"
                    type="text"
                    v-model="start_model_version.name"
                    required
                    v-b-popover.hover.top="
                      getJobProperty('start_model_version_id')
                    "
                  />
                  <label
                    @click="utils.focus('job-start-model-version-id-input')"
                    class="form-label"
                    >Start model version</label
                  >
                  <a
                    v-if="job.start_model_version_id"
                    class="hover-larger pointer"
                    @click="
                      copyToClipboard(
                        job.start_model_version_id,
                        'start_model_version',
                        'Start model version id'
                      )
                    "
                    @mouseout="resetCopyLabel()"
                    v-b-popover.hover.top="getCopyLabel('start_model_version')"
                    ><i class="fas fa-copy"
                  /></a>
                </div>
              </form>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col">
              <span class="title">Config</span>
              <form class="mt-4">
                <div v-for="item in job.items" v-bind:key="item.name">
                  <div v-if="!item.hidden" class="form-group has-feedback mt-5">
                    <div v-if="item.value_type == 'multi'">
                      <!-- <multiselect v-model="item.value" :options="item.options" :close-on-select="true" :preserve-search="true"
                                                :placeholder="item.label" :show-labels="false">
                                            </multiselect>
                                            <label class="form-label auto-adjust-form-label">{{
                                                item.label
                                                }}</label> -->
                      <input
                        class="form-control"
                        :id="item.name"
                        type="text"
                        v-model="item.value"
                        required
                      />
                      <label
                        @click="utils.focus(item.name)"
                        class="form-label"
                        >{{ item.label }}</label
                      >
                    </div>

                    <custom-checkbox
                      v-else-if="
                        item.value_type == utils.job_value_type_boolean
                      "
                      :entity="item"
                      property="value"
                      :title="item.name"
                      :text="item.label"
                      input_id="add_info"
                    />
                    <div v-else>
                      <input
                        class="form-control"
                        :id="item.name"
                        :type="utils.js_type_from_value_type(item.value_type)"
                        v-model="item.value"
                        required
                      />
                      <label
                        @click="utils.focus(item.name)"
                        class="form-label"
                        >{{ item.label }}</label
                      >
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <span class="title">Consumption</span>
              <form class="mt-4">
                <div class="form-group has-feedback mt-5">
                  <input
                    id="job-cpu-time-start-input"
                    class="form-control w90"
                    type="text"
                    v-model="job.cpu_start_time"
                    required
                  />
                  <label
                    @click="utils.focus('job-cpu-time-start-input')"
                    class="form-label"
                    >CPU start time</label
                  >
                </div>
                <div class="form-group has-feedback mt-5">
                  <input
                    id="job-cpu-time-end-input"
                    class="form-control w90"
                    type="text"
                    v-model="job.cpu_end_time"
                    required
                  />
                  <label
                    @click="utils.focus('job-cpu-time-end-input')"
                    class="form-label"
                    >CPU end time</label
                  >
                </div>
                <div class="form-group has-feedback mt-5">
                  <input
                    id="job-gpu-time-start-input"
                    class="form-control w90"
                    type="text"
                    v-model="job.gpu_start_time"
                    required
                  />
                  <label
                    @click="utils.focus('job-gpu-time-start-input')"
                    class="form-label"
                    >GPU start time</label
                  >
                </div>
                <div class="form-group has-feedback mt-5">
                  <input
                    id="job-gpu-time-end-input"
                    class="form-control w90"
                    type="text"
                    v-model="job.gpu_end_time"
                    required
                  />
                  <label
                    @click="utils.focus('job-gpu-time-end-input')"
                    class="form-label"
                    >GPU end time</label
                  >
                </div>
              </form>
            </div>
          </div>
          <hr />

          <div class="date">
            Updated at: {{ utils.showDate(job.updated_at) }}
          </div>
          <div class="date">
            Created at: {{ utils.showDate(job.created_at) }}
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import * as utils from "../utils/utils.js";

let defaultCopyLabels = {
  job: "Copy job id to clipboard",
  dataset: "Copy dataset id to clipboard",
  dataset_version: "Copy dataset version id to clipboard",
  model: "Copy model id to clipboard",
  model_version: "Copy model version id to clipboard",
  start_model: "Copy start model id to clipboard",
  start_model_version: "Copy start model version id to clipboard",
  application: "Copy application id to clipboard"
};

let defaultEntity = { name: "", id: "" };

export default {
  data: function() {
    return {
      title: "Edit job",
      job: utils.defaultJob,
      user_id: "",
      dataset: defaultEntity,
      dataset_version: defaultEntity,
      model: defaultEntity,
      model_version: defaultEntity,
      start_model: defaultEntity,
      start_model_version: defaultEntity,
      application_name: "",
      jobToDelete: {},
      actionLabel: "Save",
      deleteModalTitle: "",
      modalOk: this.deleteJob,
      deleteName: "",
      shareToDelete: {},
      utils: utils,
      copyLabel: utils.defaultCopyLabel,
      copyLabels: defaultCopyLabels
    };
  },
  computed: {
    datasetName: function() {
      if (this.dataset_version && this.dataset_version.name) {
        return this.dataset_version.name;
      }
      return this.job.dataset_version_id;
    }
  },
  methods: {
    getJob: function() {
      this.$http
        .get(`${utils.jobsApi}/${this.$route.params.job_id}`)
        .then(response => {
          this.job = response.data;

          // Get dataset info
          if (this.job.dataset_id) {
            this.$http
              .get(`${utils.datasetsApi}/${this.job.dataset_id}`)
              .then(ds_res => {
                this.dataset = ds_res.data;
                if (this.job.dataset_version_id) {
                  this.dataset_version = this.dataset.versions.find(v => {
                    return this.job.dataset_version_id == v.id;
                  });
                }
              })
              .catch(() => {
                this.dataset.name = this.job.dataset_id;
                this.dataset_version.name = this.job.dataset_version_id;
              });
          }

          // Get model info
          if (this.job.model_id) {
            this.$http
              .get(`${utils.modelApi}/${this.job.model_id}`)
              .then(m_res => {
                this["model"] = m_res.data;

                if (this.job.start_model_id == this.job.model_id) {
                  this.start_model = this.model;
                  if (this.job.start_model_version_id) {
                    this.getModelVersion(
                      "start_model_version",
                      this.job.start_model_id,
                      this.job.start_model_version_id
                    );
                  }
                }

                if (this.job.model_version_id) {
                  this.$http
                    .get(
                      `${utils.modelApi}/${this.model.id}/versions/${this.job.model_version_id}`
                    )
                    .then(versions_res => {
                      this.model_version = versions_res.data;
                    })
                    .catch(() => {
                      this.model_version.name = this.job.model_version_id;
                    });
                }
              })
              .catch(() => {
                this.model.name = this.job.model_id;
              });
          }

          if (this.job.start_model_id) {
            this.$http
              .get(`${utils.modelApi}/${this.job.start_model_id}`)
              .then(m_res => {
                this.start_model = m_res.data;
                if (this.job.start_model_version_id) {
                  this.getModelVersion(
                    "start_model_version",
                    this.job.start_model_id,
                    this.job.start_model_version_id
                  );
                }
              })
              .catch(() => {
                this.start_model.name = this.job.start_model_id;
              });
          }

          // Get application info
          if (this.job.application_id) {
            this.utils.getApplications(this.$http, () => {
              this.application_name = utils.getApplicationName(
                this.job.application_id,
                ": "
              );
            });
          }
        })
        .catch(err => {
          if (err.status == this.utils.http_status_not_found) {
            this.$router.push({
              name: "NotFound"
            });
          } else {
            this.$router.push({
              name: "Login",
              query: { redirect: this.$route.fullPath }
            });
          }
        });
    },
    getModelVersion: function(entity_name, model_id, model_version_id) {
      this.$http
        .get(`${utils.modelApi}/${model_id}/versions/${model_version_id}`)
        .then(versions_res => {
          this[entity_name] = versions_res.data;
        })
        .catch(() => {
          this[entity_name].name = model_version_id;
        });
    },
    updateJob: function() {
      this.$http.put(`${utils.jobsApi}/${this.job.id}`, this.job);
    },
    deleteJob: function() {
      this.$http
        .delete(
          `${utils.jobsApi}/${this.job.id}/versions/${this.versionToDelete.id}`
        )
        .then(() => {
          this.$router.push(`/jobs`);
        });
    },

    startDeleteJob: function(job) {
      this.deleteModalTitle = "Delete job";
      this.jobToDelete = job;
      this.modalOk = this.deleteJob;
      this.deleteName = job.name;
      this.$bvModal.show("simple-modal");
    },

    cancelDelete: function() {
      this.jobToDelete = {};
    },
    navigateUrl: function(params) {
      let url = utils.generateUrl(params);

      this.$router.push(url);
    },
    copyToClipboard: function(text, type, label) {
      this.utils.copyToClipboard(text);
      this.copyLabels[type] = `${label} copied to clipboard!`;
    },
    resetCopyLabel: function() {
      setTimeout(() => {
        this.copyLabels = defaultCopyLabels;
      }, 500);
    },
    getCopyLabel: function(type) {
      return this.copyLabels[type];
    },
    getJobProperty: function(prop) {
      return this.job[prop];
    }
  },
  mounted() {
    this.getJob();
    this.$ga.page("edit-job");
    utils.showMenu(true, true, this);
  }
};
</script>
