<template>
  <div>
    <div class="text-container">
      <textarea
        id="textarea"
        type="text"
        rows="10"
        class="col-sm-10"
        v-model="nlpInput"
        :placeholder="utils.text_input_placeholder"
      />
    </div>
    <div>
      <button
        class="second-action-button action-btn"
        :disabled="btn_disabled"
        @click="predict"
      >
        {{ prediction_label }}
      </button>
    </div>
  </div>
</template>

<script>
import * as utils from "../../utils/utils.js";

export default {
  name: "LanguageModel",
  props: {
    endpoint: String
  },
  data() {
    return {
      force_disabled: false,
      prediction_label: utils.generate_label,
      nlpInput: "",
      utils: utils
    };
  },
  computed: {
    btn_disabled: function() {
      if (this.force_disabled) {
        return true;
      }
      return this.nlpInput.length == 0;
    }
  },
  methods: {
    predict: function() {
      this.force_disabled = true;
      this.prediction_label = utils.generate_in_progress;

      this.nlpInput = this.nlpInput.trim();

      var data = {
        input_text: this.nlpInput
      };

      this.$http
        .post(this.endpoint, data)
        .then(res => {
          this.prediction_label = utils.generate_label;
          this.nlpInput = res.data.prediction;
          this.force_disabled = false;
        })
        .catch(() => {
          this.force_disabled = false;
          this.prediction_label = utils.generate_label;
        });
    }
  }
};
</script>

<style scoped lang="scss">
form {
  color: #666;
  font-size: 18px;

  label {
    font-weight: 600;
  }
}
</style>
