<template>
  <div id="jobs-view">
    <SimpleModal
      title="Delete model"
      :name="job.name"
      :ok="doDelete"
      :hidden="cancelDelete"
    ></SimpleModal>
    <Header
      :brand="title"
      :entity="{}"
      :action="getJobs"
      :actionLabel="actionLabel"
      :searchLabel="searchLabel"
      :searchVisible="true"
    />
    <div></div>
    <section class="main">
      <div class="row p-3 overview">
        <custom-checkbox
          title=""
          :entity="this"
          property="finished"
          text="Fetch all jobs, including finished ones."
          input_id="finished-jobs-input"
        />
      </div>
      <div class="container-fluid" v-show="jobs.length" v-cloak>
        <div class="row ">
          <b-table
            striped
            hover
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter="search"
            :items="jobs"
            :fields="fields"
            sort-icon-left
          >
            <template v-slot:cell(manage)="row">
              <a v-on:click.stop.prevent @click="editJob(row.item)" class="btn">
                <i class="far fa-edit" />
              </a>
              <a
                v-on:click.stop.prevent
                @click="startDelete(row.item)"
                class="btn"
              >
                <i class="far fa-trash-alt" />
              </a>
            </template>
            <template v-slot:cell(delete)="row">
              <b-button size="sm" @click="startDelete(row.item)" class="mr-2">
                Delete
              </b-button>
            </template>
            <template v-slot:cell(cpu_duration)="row">
              {{ cpuDuration(row) }}
            </template>
            <template v-slot:cell(gpu_duration)="row">
              {{ gpuDuration(row) }}
            </template>
            <template v-slot:cell(model_id)="row">
              <a
                :href="
                  utils.generateUrl([
                    {
                      name: '#',
                      val: ''
                    },
                    {
                      name: 'models',
                      val: row.item.model_id
                    },
                    {
                      name: 'edit'
                    }
                  ])
                "
                >{{ getModelName(row.item) }}</a
              >
            </template>
            <template v-slot:cell(model_version_id)="row">
              <a
                :href="
                  utils.generateUrl([
                    {
                      name: '#',
                      val: ''
                    },
                    {
                      name: 'models',
                      val: row.item.model_id
                    },
                    {
                      name: 'edit'
                    }
                  ])
                "
                >{{ getModelVersionName(row.item) }}</a
              >
            </template>
            <template v-slot:cell(dataset_version_id)="row">
              <a
                class="pointer"
                :href="
                  utils.generateUrl([
                    {
                      name: '#',
                      val: ''
                    },
                    {
                      name: 'datasets',
                      val: row.item.dataset_id
                    },
                    {
                      name: 'versions',
                      val: row.item.dataset_version_id
                    }
                  ])
                "
                >{{ getDatasetName(row.item) }}</a
              >
            </template>
            <template v-slot:cell(id)="row">
              <span :title="row.item.id">
                {{ row.item.id.slice(0, 8) + "... " }}
              </span>

              <a
                class="hover-larger pointer"
                @click="copyToClipboard(row.item)"
                @mouseout="resetCopyLabel()"
                v-b-popover.hover.top="getCopyLabel()"
                ><i class="fas fa-copy"
              /></a>
            </template>
            <template v-slot:cell(updated_at)="row">
              {{ utils.formatDateAndTime(row.item.updated_at) }}
            </template>
            <template v-slot:cell(application_id)="row">
              <span
                class="applicationName"
                :title="utils.getApplicationTitle(row.item.application_id)"
                v-html="utils.getApplicationName(row.item.application_id)"
              >
              </span>
            </template>
          </b-table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import * as utils from "../utils/utils.js";

var fields = [
  { key: "name", sortable: true },
  { key: "status", sortable: true },
  { key: "status_message" },
  { key: "dataset_version_id", label: "Dataset" },
  { key: "model_id", label: "Model" },
  { key: "model_version_id", label: "Model version" },
  { key: "application_id", label: "Application" },
  { key: "updated_at", sortable: true },
  { key: "id", sortable: true },
  { key: "manage" }
];

let defaultCopyLabel = "Copy job id to clipboard.";

export default {
  data: function() {
    return {
      title: "Jobs",
      jobs: [],
      job: utils.defaultJob,
      fields: fields,
      nameState: null,
      sortBy: "updated_at",
      sortDesc: true,
      search: "",
      actionLabel: "Refresh",
      searchLabel: "Search jobs...",
      finished: false,
      started: false,
      utils: utils,
      datasets: [],
      models: [],
      modelVersions: [],
      copyJobIdLabel: utils.defaultCopyLabel
    };
  },
  methods: {
    getJobs: function() {
      this.utils.getApplications(this.$http);
      var url = utils.jobsApi;
      // Keep these together
      var status = "waiting,started,error";

      let job_id = this.$route.params.job_id;

      if (job_id) {
        this.finished = true;
      }

      if (this.finished) {
        status = `${status},finished`;
      }

      url = `${url}?status=${status}`;

      this.$http.get(url).then(res => {
        this.jobs = res.data;

        var datasetIds = new Set();
        var modelIds = new Set();

        this.jobs.forEach(v => {
          // dataset_id can be empty
          if (v.dataset_id) {
            datasetIds.add(v.dataset_id);
          }

          if (v.model_id) {
            modelIds.add(v.model_id);
          }
        });

        datasetIds.forEach(d_id => {
          this.$http.get(`${utils.datasetsApi}/${d_id}`).then(res => {
            this.datasets.push(res.data);
          });
        });

        modelIds.forEach(m_id => {
          this.$http.get(`${utils.modelApi}/${m_id}`).then(res => {
            this.$http
              .get(`${utils.modelApi}/${m_id}/versions`)
              .then(versions_res => {
                res.data.versions = versions_res.data;
                this.models.push(res.data);
              });
          });
        });
      });
    },
    getCopyLabel: function() {
      return this.copyJobIdLabel;
    },
    resetCopyLabel: function() {
      setTimeout(() => {
        this.copyJobIdLabel = defaultCopyLabel;
      }, 500);
    },
    editJob: function(job) {
      this.$router.push(`/jobs/${job.id}`);
    },
    startDelete: function(job) {
      this.job = job;
      this.$bvModal.show("simple-modal");
    },
    doDelete: function() {
      var deleteUrl = `${utils.jobsApi}/${this.job.id}`;
      this.$http.delete(deleteUrl).then(() => {
        this.getJobs();
      });
    },
    getModelName: function(job) {
      var model = this.models.find(m => {
        return m.id == job.model_id;
      });

      if (!model) {
        return job.model_id;
      }

      return model.name;
    },
    getModelVersionName: function(job) {
      var model = this.models.find(m => {
        return m.id == job.model_id;
      });

      if (!model) {
        return job.model_version_id;
      }

      var mv = model.versions.find(v => {
        return v.id == job.model_version_id;
      });

      if (!mv) {
        return job.model_version_id;
      }

      return `${mv.name}  - ${mv.version} (${mv.version_number})`;
    },
    getDatasetName: function(job) {
      var ds = this.datasets.find(a => {
        return a.id == job.dataset_id;
      });

      if (!ds) {
        return job.dataset_version_id;
      }

      var ds_version = ds.versions.find(v => {
        return v.id == job.dataset_version_id;
      });

      if (!ds_version) {
        return ds.name;
      }

      return `${ds.name} (${ds_version.name})`;
    },
    cancelDelete: function() {
      this.job = utils.defaultJob;
    },
    handleOk: function(bvModalEvt) {
      bvModalEvt.preventDefault();

      var httpMethod = "put";

      this.$http[httpMethod](`${utils.jobsApi}/${this.job.id}`, this.job).then(
        () => {
          this.$bvModal.hide("manage-job");
          this.job = utils.defaultJob;
        }
      );
    },
    resetModal: function() {
      this.nameState = null;
    },
    gpuDuration(row) {
      return this.duration(row.item, "gpu");
    },
    cpuDuration(row) {
      return this.duration(row.item, "cpu");
    },
    duration(job, platform) {
      let start = job[`${platform}_start_time`];

      if (start.length == 0) {
        return 0;
      }

      let end = job[`${platform}_end_time`];

      if (end.length == 0) {
        return 0;
      }

      let gpu_start = new Date(start);

      let gpu_end = new Date(end);

      let diff = gpu_end - gpu_start;

      // first "11": from position 11 in the ISO string
      // second "11": the length you want to display (optional)
      return utils.formatTime(diff);
    },
    copyToClipboard: function(job) {
      this.utils.copyToClipboard(job.id);
      this.copyJobIdLabel = "Job id copied to clipboard!";
    }
  },
  watch: {
    finished: function() {
      this.getJobs();
    }
  },
  mounted() {
    this.getJobs();
    this.$ga.page("view-jobs");
    utils.showMenu(true, true, this);
  }
};
</script>

<style scoped>
.container {
  max-width: 1500px;
}

button.version-button {
  width: 100px;
}

.dropdown {
  position: relative;
  width: 90%;
  max-width: 350px;
  margin: 0 10px;
}

.dropdown-input,
.dropdown-selected {
  width: 100%;
  padding: 10px 16px;
  border: 1px solid transparent;
  background: #edf2f7;
  line-height: 1.5em;
  outline: none;
  border-radius: 8px;
}

.dropdown-input:focus,
.dropdown-selected:hover {
  background: #fff;
  border-color: #e2e8f0;
}

.dropdown-input::placeholder {
  opacity: 0.7;
}

.dropdown-selected {
  font-weight: bold;
  cursor: pointer;
}

.dropdown-list {
  position: absolute;
  width: 100%;
  max-height: 500px;
  margin-top: 4px;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.dropdown-item {
  display: flex;
  width: 100%;
  padding: 11px 16px;
  cursor: pointer;
}

.dropdown-item:hover {
  background: #edf2f7;
}

.dropdown-item-flag {
  max-width: 24px;
  max-height: 18px;
  margin: auto 12px auto 0px;
}
</style>
