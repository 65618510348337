<template>
  <div>
    <section class="main">
      <Header
        :entity="headerEntity"
        :actionLabel="actionLabel"
        :action="goToApp"
        :showLogo="true"
      />
      <div class="container-fluid">
        <div class="reset-box">
          <div class="row bg-light mx-auto w-25">
            <div class="col px-5 py-4 text-left">
              <p>Unfortunately,</p>
              <p>we were not able to find what you are looking for.</p>
              <p>You could try these links instead:</p>
              <p class="pl-3"><a href="/">SeeMe.ai app</a></p>
              <p class="pl-3"><a href="/#/">Your AI models</a></p>
              <p class="pl-3"><a href="/#/datasets">Your datasets</a></p>
              <p class="pl-3"><a href="/#/jobs">Your jobs</a></p>
            </div>
          </div>
          <hr />

          <div class="container-fluid">
            <div class="row">
              No account yet?
              <a class="more-link" href="/#/register"> Sign up</a>
            </div>
            <div class="row">
              Have an account? <a class="more-link" href="/#/login">Log in</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import * as utils from "../utils/utils.js";
export default {
  name: "NotFound",
  data: function() {
    return {
      headerEntity: { name: utils.not_found_label },
      actionLabel: "Go to app",

      utils: utils
    };
  },
  methods: {
    goToApp: function() {
      this.$router.push("/");
    }
  },
  mounted() {
    utils.showMenu(false, false, this);
    this.$ga.page("notfound");
  }
};
</script>
d
