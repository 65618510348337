<template>
  <div>
    <form class="w-25 mx-auto" v-show="show_form">
      <div class="">
        <label for="prediction">Prediction:</label>
        <span id="prediction">
          {{ prediction }}
        </span>
      </div>
    </form>
    <div class="text-container">
      <form>
        <div
          v-for="field in inputFields"
          class="col-auto mb-5 ml-2 mr-2"
          :key="field.name"
        >
          <b-form-group :label="field.name">
            <!-- Note: adding a 'label-for' tag in the b-form-group directly above may result in a javascript error, unless you check that the field names can also be used as javascript id (e.g. no '-' (minus) in the string) -->
            <multiselect
              v-if="field.type == 'cat'"
              id="content-type-input"
              v-model="structuredRequest[field.name]"
              :options="field.values"
              :placeholder="`Select ${field.name}`"
              :searchable="true"
              :show-labels="false"
            >
            </multiselect>

            <b-form-input
              v-else
              :id="field.name"
              v-model="structuredRequest[field.name]"
              type="number"
            >
            </b-form-input>
            <div v-if="field.examples">
              Exampes:
              <span v-for="(example, index) in field.examples" :key="example">
                <a
                  @click.stop.prevent="addPropertyValue(field.name, example)"
                  href="#"
                  >{{ example }}</a
                >
                <span v-if="index < field.examples.length - 1"> - </span>
              </span>
            </div>
          </b-form-group>
        </div>
      </form>
    </div>
    <div>
      <button
        class="second-action-button action-btn"
        :disabled="btn_disabled"
        @click="predict"
      >
        {{ prediction_label }}
      </button>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import * as utils from "../../utils/utils.js";

export default {
  name: "Tabular",
  props: {
    endpoint: String,
    model_config: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      prediction: "",
      force_disabled: false,
      prediction_label: utils.prediction_label,
      show_form: false,
      inputFields: [],
      structuredRequest: {},
      utils: utils
    };
  },
  mounted() {
    if (this.model_config) {
      this.processConfig(this.model_config);
    }
  },
  watch: {
    model_config: function(newVal) {
      this.processConfig(newVal);
    }
  },
  computed: {
    btn_disabled: function() {
      if (this.force_disabled) {
        return true;
      }

      this.inputFields.forEach(inputField => {
        if (
          !this.structuredRequest[inputField["name"]] ||
          this.structuredRequest[inputField["name"]].length == 0
        ) {
          return true;
        }
      });
      return false;
    }
  },
  methods: {
    predict: function() {
      this.prediction = "";
      this.force_disabled = true;
      this.prediction_label = utils.prediction_in_progress_label;

      var structured_data = {
        input_text: JSON.stringify(this.structuredRequest)
      };

      this.$http
        .post(this.endpoint, structured_data)
        .then(res => {
          this.prediction_label = utils.prediction_label;
          this.prediction = res.data.prediction;
          this.force_disabled = false;
          this.show_form = true;
        })
        .catch(() => {
          this.force_disabled = false;
          this.prediction_label = utils.prediction_label;
        });
    },
    addPropertyValue: function(fieldName, value) {
      Vue.set(this.structuredRequest, fieldName, value);
    },
    processConfig: function(config) {
      this.structuredRequest = {};
      var parsed = JSON.parse(config);

      if (Array.isArray(parsed)) {
        // Old agent, when  model_config was only the 'fields' property
        this.inputFields = parsed;
      } else {
        // Agent supports multiple config properties: 'fields', 'feature_importance'
        this.inputFields = parsed["fields"];
      }

      this.inputFields.forEach(i => {
        if (i["default"] || i["default"] == 0) {
          this.addPropertyValue(i["name"], i["default"]);
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
form {
  color: #666;
  font-size: 18px;

  label {
    font-weight: 600;
  }
}
</style>
