import Vue from "vue";
import VueResource from "vue-resource";
import App from "./App.vue";
import router from "./router";
import VueAnalytics from "vue-analytics";
import { googleAnalyticsId } from "./utils/utils.js";
import VueSidebarMenu from "./components/SidebarMenu.vue";

import Keypress from "vue-keypress";

Vue.component("Keypress", Keypress);
Vue.component("sidebar-menu", VueSidebarMenu);

import MultiSelect from "vue-multiselect";
Vue.component("multiselect", MultiSelect);

Vue.use(VueAnalytics, {
  id: googleAnalyticsId
});

// --- Header ---
import Header from "./components/Header";
Vue.component("Header", Header);

// --- Simple modal ---
import SimpleModal from "./components/SimpleModal";
Vue.component("SimpleModal", SimpleModal);

// --- Custom checkbox ---
import CustomCheckbox from "./components/CustomCheckbox";
Vue.component("CustomCheckbox", CustomCheckbox);

// --- Inference views ---

// - Image classification -
import ImageClassification from "./components/inference-views/ImageClassification";
Vue.component("ImageClassification", ImageClassification);

// - Text classification -
import TextClassification from "./components/inference-views/TextClassification";
Vue.component("TextClassification", TextClassification);

// - Object detection -
import ObjectDetection from "./components/inference-views/ObjectDetection";
Vue.component("ObjectDetection", ObjectDetection);

// - Named Entity Recognition -
import NamedEntityRecognition from "./components/inference-views/NamedEntityRecognition";
Vue.component("NamedEntityRecognition", NamedEntityRecognition);

// - Tabular -
import Tabular from "./components/inference-views/Tabular";
Vue.component("Tabular", Tabular);

// - OCR -
import OpticalCharacterRecognition from "./components/inference-views/OpticalCharacterRecognition";
Vue.component("OpticalCharacterRecognition", OpticalCharacterRecognition);

// - Language Model -
import LanguageModel from "./components/inference-views/LanguageModel";
Vue.component("LanguageModel", LanguageModel);

// - LLM (Large Language Model) -
import LLM from "./components/inference-views/LLM";
Vue.component("LLM", LLM);

// - Speech To Text -
import SpeechToText from "./components/inference-views/SpeechToText";
Vue.component("SpeechToText", SpeechToText);

Vue.use(VueResource);

import BootstrapVue from "bootstrap-vue";

Vue.use(BootstrapVue);

var username = localStorage.getItem("username");
var password = localStorage.getItem("apikey");

Vue.http.headers.common["Authorization"] = `${username}:${password}`;

Vue.config.productionTip = false;

new Vue({
  router,
  render: function(h) {
    return h(App);
  }
}).$mount("#app");
