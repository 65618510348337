<template>
  <div>
    <div class="row">
      <div :class="show_form ? 'col-6' : 'col'" class="align-items-center">
        <div
          class="drop-zone mx-auto h-100"
          @click="selectImage"
          @dragover="dragover"
          @dragend="enddrag"
          @dragleave="enddrag"
          @drop="drop"
        >
          <span v-if="showDropZone" class="drop-zone__prompt"
            >Drop your image/pdf here or click to upload</span
          >
          <img
            class="drop-zone__thumb w-50"
            v-if="!showDropZone && showImg"
            :src="img"
          />

          <input
            id="img-input"
            type="file"
            name="file"
            :accept="acceptExtensions"
            @change="showSelectedImage"
            class="drop-zone__input"
          />
          <div v-if="!showDropZone && !showImg">
            <pdf :src="pdf_source" :page="currentPage"></pdf>

            <div>{{ currentPage }} / {{ numPages }}</div>
            <a
              v-if="hasPreviousPage()"
              @click.prevent.stop="showPreviousPage()"
            >
              <i class="fas fa-chevron-left"></i>
            </a>
            <a v-if="hasNextPage()" @click.prevent.stop="showNextPage()">
              <i class="fas fa-chevron-right"></i>
            </a>
          </div>
        </div>
      </div>
      <div class=" col-6" v-if="show_form">
        <form class="w-75 mt-5 mx-auto" v-show="show_form">
          <div class="">
            <label for="prediction">Prediction:</label>
            <textarea
              id="prediction"
              v-if="prediction && prediction.inference_items"
              v-model="prediction.inference_items[currentPage - 1].prediction"
              rows="18"
            >
            </textarea>
            <div class="mt-2">
              <a
                v-if="!showImg"
                @click="copyCurrentPageToClipboard()"
                class="hover-larger pointer pr-3"
                @mouseout="resetCopyLabel()"
                v-b-popover.hover.top="getCopyLabel(false)"
              >
                <i class="far fa-copy"></i>
              </a>
              <a
                @click="copyAllToClipboard()"
                class="hover-larger pointer pl-3"
                @mouseout="resetCopyLabel()"
                v-b-popover.hover.top="getCopyLabel(true)"
              >
                <i class="fas fa-copy"></i>
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
    <label id="upload-label">{{ uploadLbl }}</label>
    <div>
      <button
        class="second-action-button action-btn"
        v-if="!btn_disabled"
        @click="selectImage"
      >
        {{ prediction_label }}
      </button>
      <multiselect
        v-model="inferenceOptionSelection['psm']"
        :options="inferenceOptions"
        label="label"
        track-by="label"
        :multiple="false"
        :close-on-select="true"
        :preseve-search="true"
        placeholder="How should the image be split into lines of text?"
        class="w-50 mx-auto pt-4"
      />
    </div>
  </div>
</template>

<script>
import * as utils from "../../utils/utils.js";
import pdf from "vue-pdf";

let defaultCopyCurrentPageLabel = "Copy current page to clipboard";
let defaultCopyAllLabel = "Copy full text to clipboard";

let copiedCurrentPageLabel = "Copied current page to clipboard";
let copiedAllLabel = "Copied all text to clipboard";

export default {
  name: "OpticalCharacterRecognition",
  props: {
    endpoint: String
  },
  components: {
    pdf
  },
  data() {
    return {
      acceptExtensions: utils.opticalCharacterExtensions,
      img: "",
      pdf_source: "",
      embedSrc: "",
      prediction: {},
      confidence: "",
      force_disabled: false,
      prediction_label: utils.ocr_label,
      show_form: false,
      uploadLbl: "",
      showImg: true,
      numPages: 0,
      currentPage: 1,
      utils: utils,
      copyCurrentPageLabel: defaultCopyCurrentPageLabel,
      copyAllLabel: defaultCopyAllLabel,
      inferenceOptionSelection: utils.tesseract_psm_options[3],
      inferenceOptions: utils.tesseract_psm_options
    };
  },
  computed: {
    btn_disabled: function() {
      if (this.force_disabled) {
        return true;
      }

      return !this.img && !this.pdf_source;
    },
    showDropZone: function() {
      return !this.img && !this.pdf_source;
    }
  },
  methods: {
    showSelectedImage: async function(e) {
      var inputFile = e.target.files[0];

      if (!inputFile) {
        return;
      }

      if (inputFile.type == utils.file_type_pdf) {
        this.processPDF(inputFile);
      } else {
        this.processImg(inputFile);
      }

      this.predict();
    },
    dragover: function(e) {
      e.preventDefault();

      let dropzoneEl = document.querySelector(".drop-zone");
      dropzoneEl.classList.add("drop-zone--over");
    },
    enddrag: function() {
      let dropzoneEl = document.querySelector(".drop-zone");
      dropzoneEl.classList.remove("drop-zone--over");
    },
    drop: function(e) {
      e.preventDefault();

      if (e.dataTransfer.files.length) {
        var inputFile = e.dataTransfer.files[0];

        let inputEl = utils.el("img-input");
        inputEl.files = e.dataTransfer.files;

        if (inputFile.type == utils.file_type_pdf) {
          this.processPDF(inputFile);
        } else {
          this.processImg(inputFile);
        }
      }

      let dropzoneEl = document.querySelector(".drop-zone");
      dropzoneEl.classList.remove("drop-zone--over");
      this.predict();
    },
    processImg: function(imgFile) {
      this.uploadLbl = imgFile.name;

      this.img = URL.createObjectURL(imgFile);

      this.showImg = true;
    },
    processPDF: function(pdfFile) {
      var url = URL.createObjectURL(pdfFile);
      this.pdf_source = pdf.createLoadingTask(url);
      this.pdf_source.promise.then(lp => {
        this.numPages = lp.numPages;
      });

      this.showImg = false;
    },
    selectImage: function() {
      utils.el("img-input").click();
    },
    hasNextPage: function() {
      return this.currentPage < this.numPages;
    },
    showNextPage: function() {
      this.currentPage += 1;
    },
    hasPreviousPage: function() {
      return this.currentPage > 1;
    },
    showPreviousPage: function() {
      this.currentPage -= 1;
    },
    resetCopyLabel: function() {
      this.copyCurrentPageLabel = defaultCopyCurrentPageLabel;
      this.copyAllLabel = defaultCopyAllLabel;
    },
    getCopyLabel: function(all) {
      if (all) {
        return this.copyAllLabel;
      }

      return this.copyCurrentPageLabel;
    },
    copyCurrentPageToClipboard: function() {
      var currentPageText = this.prediction.inference_items[
        this.currentPage - 1
      ].prediction;
      this.utils.copyToClipboard(currentPageText);
      this.copyCurrentPageLabel = copiedCurrentPageLabel;
    },
    copyAllToClipboard: function() {
      var allText = "";
      this.prediction.inference_items.forEach(p => {
        allText += p.prediction;
      });

      this.utils.copyToClipboard(allText);
      this.copyAllLabel = copiedAllLabel;
    },
    predict: function() {
      // console.log(this.inferenceOptionSelection);
      this.prediction = "";
      this.confidence = "";
      this.force_disabled = true;
      this.prediction_label = utils.ocr_in_progress_label;

      let inputEl = utils.el("img-input");
      var imgFile = inputEl.files[0];
      var formData = new FormData();

      formData.append("file", imgFile);

      this.show_form = true;

      this.$http
        .post(this.endpoint, formData)
        .then(res => {
          if (res.data.inference_items.length > 0) {
            this.prediction = res.data;
          }

          this.force_disabled = false;
          this.prediction_label = utils.ocr_another_label;
        })
        .catch(() => {
          this.force_disabled = false;
          this.prediction_label = utils.ocr_another_label;
        });
    }
  }
};
</script>

<style scoped lang="scss">
.drop-zone {
  max-width: 100% !important;
  // display: block !important;
}

#prediction {
  width: 100%;
}

form {
  color: #666;

  label {
    font-weight: 600;
  }
}

textarea {
  border-color: #ccc;
}
</style>
