<template>
  <div>
    <section class="main">
      <Header
        :entity="headerEntity"
        :actionLabel="actionLabel"
        :action="goToApp"
        :showLogo="true"
      />
      <div class="container-fluid">
        <div class="reset-box">
          <div class="row bg-light mx-auto text-center reset">
            <div class="col p-5 d-flex justify-content-center">
              <form v-if="show_input_form" accept-charset="utf-8" novalidate>
                <label v-if="error_msg" class="error">{{ error_msg }}</label>

                <div class="form-group has-feedback">
                  <input
                    id="password-input"
                    class="form-control"
                    type="password"
                    v-model="password"
                    required
                  />
                  <label
                    @click="utils.focus('password-input')"
                    class="form-label"
                    >New password</label
                  >
                </div>
                <div class="form-group has-feedback">
                  <input
                    id="password-repeat-input"
                    class="form-control"
                    type="password"
                    v-model="repeat_password"
                    required
                  />
                  <label
                    @click="utils.focus('password-repeat-input')"
                    class="form-label"
                    >Verify password</label
                  >
                </div>
                <div class="row">
                  <div class="col-xs-4 login-button">
                    <button
                      class="btn-primary action-btn reset-btn mx-auto"
                      @click="resetPassword"
                      :disabled="btn_disabled"
                    >
                      {{ reset_button_label }}
                    </button>
                  </div>
                </div>
              </form>
              <form v-else>
                <div class="title text-left">{{ result_title }}</div>
                <div class="text-left">
                  {{ result_body }}<a :href="action_url">{{ result_action }}</a
                  >.
                </div>
              </form>
            </div>
          </div>
          <hr />

          <div class="container-fluid">
            <div class="row">
              No account yet?
              <a class="more-link" href="/#/register"> Sign up</a>
            </div>
            <div class="row">
              Have an account? <a class="more-link" href="/#/login">Log in</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import * as utils from "../utils/utils.js";
export default {
  name: "ResetPasswordRequest",
  data: function() {
    return {
      headerEntity: { name: utils.reset_password_label },
      actionLabel: "Go to app",
      user_id: "",
      reset_token: "",
      password: "",
      repeat_password: "",
      error: "",
      error_msg: "",
      force_disable_btn: false,
      reset_button_label: utils.reset_password_label,
      utils: utils,
      show_input_form: true,
      result_title: "",
      result_body: "",
      result_action: "",
      action_url: ""
    };
  },
  methods: {
    goToApp: function() {
      this.$router.push("/");
    },
    checkReset: function() {
      let check_reset_url = `${this.utils.passwordResetApi}/${this.user_id}/${this.reset_token}`;

      this.$http
        .get(check_reset_url)
        .then(() => {})
        .catch(err => {
          this.show_input_form = false;
          if (err.status == 409) {
            this.result_title = "Token expired";
            this.result_body = "Please ";
            this.result_action = "request a new password";
            this.action_url = "/#/resetpassword";
            this.show_input_form = false;
          } else if (err.status == 404) {
            this.result_title = utils.not_found_label;
            this.result_body = utils.suggest_reset_label;
            this.result_action = utils.reset_action_label;
          } else if (err.status == 400) {
            this.error_msg =
              "The request was incorrect and could not be processed.";
          } else {
            this.error_msg =
              "An error occured. Please try again or contact support@seeme.ai";
          }
          this.action_url = "/#/resetpassword";
        });
    },
    resetPassword: function() {
      this.error_msg = "";

      if (this.password != this.repeat_password) {
        this.error_msg = "Password do not match";
        return;
      }

      if (this.password.length == 0) {
        this.error_msg = "Password cannot be empty";
        return;
      }

      this.force_disable_btn = true;

      this.reset_button_label = this.utils.reset_requesting;

      var password_update = {
        user_id: this.user_id,
        reset_token: this.reset_token,
        password: this.password,
        repeat_password: this.repeat_password
      };

      this.$http
        .put(
          `${utils.passwordResetApi}/${this.user_id}/${this.reset_token}`,
          password_update
        )
        .then(() => {
          this.force_disable_btn = false;

          this.reset_button_label = this.utils.reset_password_label;

          this.show_input_form = false;
          this.result_title = utils.success_label;
          this.result_body = utils.success_reset_label;
          this.result_action = utils.log_in_action_label;
          this.action_url = "/#/login";
        })
        .catch(err => {
          this.reset_button_label = this.utils.reset_password_label;
          this.show_input_form = true;
          this.force_disable_btn = false;
          this.password = "";
          this.repeat_password = "";
          if (err.status == 409) {
            this.result_title = "Token expired";
            this.result_body = "Please ";
            this.result_action = "request a new password";
            this.action_url = "/#/resetpassword";
            this.show_input_form = false;
          } else if (err.status == 400) {
            this.error_msg =
              "The request was incorrect and could not be processed.";
          } else {
            this.error_msg =
              "An error occured. Please try again or contact support@seeme.ai";
          }
        });
    }
  },
  mounted() {
    utils.showMenu(false, false, this);
    this.$ga.page("reset_password");
    this.user_id = this.$route.params.user_id;
    this.reset_token = this.$route.params.reset_token;
    this.checkReset();
  },
  computed: {
    btn_disabled: function() {
      if (this.force_disable_btn) {
        return this.force_disable_btn;
      }

      if (this.password.length == 0) {
        return true;
      }

      if (this.repeat_password != this.password) {
        return true;
      }

      return false;
    }
  }
};
</script>
