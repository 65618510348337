<template>
  <div>
    <SimpleModal
      title="Delete model"
      :name="model.name"
      :ok="doDelete"
      :hidden="cancelDelete"
    ></SimpleModal>
    <Header
      :entity="{}"
      :action="addModel"
      :searchLabel="searchLabel"
      :actionLabel="actionLabel"
      :searchVisible="true"
    />
    <div>
      <!-- Add Dataset modal -->
      <b-modal
        id="add-model"
        ref="modal"
        title="Add Model"
        @ok="handleOk"
        @shown="initModal"
        :ok-disabled="addModelOkDisabled()"
        size="lg"
      >
        <form ref="form" @submit.stop.prevent="handleSubmit" novalidate>
          <div class="form-group has-feedback">
            <input
              id="model-name-input"
              class="form-control"
              type="text"
              v-model="model.name"
              required
            />
            <label @click="utils.focus('model-name-input')" class="form-label"
              >Name</label
            >
          </div>
          <custom-checkbox
            title="Auto convert"
            :entity="model"
            property="auto_convert"
            text="Automatically convert uploaded models to supported platforms (ONNX, Core ML,
              TensorFLow Lite, ...)"
            input_id="auto-convert-input"
            top_class="px-3"
          />
          <div class="form-group has-feedback mt-5">
            <textarea
              id="model-description-input"
              class="form-control"
              v-model="model.description"
              required
              rows="10"
            ></textarea>
            <label
              @click="utils.focus('model-description-input')"
              class="form-label"
              >Description</label
            >
          </div>
        </form>
      </b-modal>
    </div>
    <!-- Overview Models -->
    <section class="main">
      <div class="container-fluid" v-show="filteredModels.length == 0" v-cloak>
        <!-- TODO add empty state here -->
        <div class="row p-3 overview justify-content-center">
          {{ emptyState() }}
        </div>
      </div>
      <div class="container-fluid" v-show="models.length" v-cloak>
        <div class="row p-3 overview">
          <div
            v-for="model in filteredModels"
            class="col-lg-3 mb-5 d-flex align-items-stretch"
            :key="model.id"
            :id="model.id"
          >
            <div class="card model">
              <div>
                <img
                  :src="getImgUrl(model)"
                  img-alt="Model logo"
                  @click="showModel(model)"
                  :class="getImgClass(model)"
                  class="pointer img-fluid mx-auto d-block img-card-top"
                />
              </div>
              <div class="card-body d-flex flex-column">
                <h5
                  class="card-title h2 pointer align-self-center"
                  @click="showModel(model)"
                >
                  {{ model.name }}
                </h5>
                <p class="card-text text-left truncate-3 min-height-3">
                  {{ model.description }}
                </p>
                <div>
                  <a
                    v-if="utils.canEdit(model)"
                    v-on:click.stop.prevent
                    @click="editModel(model)"
                    class="btn"
                  >
                    <i class="far fa-edit" />
                  </a>
                  <a
                    v-if="utils.canDelete(model)"
                    v-on:click.stop.prevent
                    @click="startDelete(model)"
                    class="btn"
                    href="#"
                  >
                    <i class="far fa-trash-alt" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import * as utils from "../utils/utils.js";

let defaultModel = {
  name: "",
  description: "",
  auto_convert: true
};

export default {
  name: "Models",
  data: function() {
    return {
      search: "",
      model: defaultModel,
      models: [],
      filtered_models: [],
      share_type: "all",
      actionLabel: "Add model",
      searchLabel: "Search models...",
      nameState: null,
      utils: utils
    };
  },
  computed: {
    filteredModels: function() {
      var search = this.search.toLowerCase();

      return this.filtered_models.filter(model => {
        if (search == "" || search.length < 2) return true;

        return (
          model.name.toLowerCase().includes(search) ||
          model.description.toLowerCase().includes(search)
        );
      });
    }
  },
  methods: {
    filter_models() {
      return this.models.filter(model => {
        if (this.share_type == "all") {
          return true;
        } else if (this.share_type == "public") {
          return model.public;
        }

        if (this.share_type == "owned") {
          return model.user_id == utils.user_id();
        } else if (this.share_type == "shared") {
          return model.shared_with_me;
        }

        return true;
      });
    },
    emptyState: function() {
      return "There are no models available for you at the moment.";
    },
    refreshModels() {
      this.$http
        .get(utils.modelApi)
        .then(response => {
          var sorted = this.utils.sort_dates(response.data, "updated_at", 1);
          this.models = sorted;
          this.filtered_models = sorted;
          this.model = defaultModel;

          if (this.$route.query.filter) {
            this.share_type = this.$route.query.filter;
            this.filtered_models = this.filter_models();
          }
        })
        .catch(() => {
          utils.redictLogin(this, this.$route.fullPath);
        });
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    handleOk: function() {
      this.$http.post(utils.modelApi, this.model).then(res => {
        this.$bvModal.hide("add-model");
        this.model = defaultModel;
        this.editModel(res.data);
      });
    },
    addModelOkDisabled: function() {
      if (this.model.name.length < 1) {
        return true;
      }

      return false;
    },
    addModel: function() {
      this.$bvModal.show("add-model");
    },
    initModal: function() {
      var modelNameInput = this.utils.el("model-name-input");
      modelNameInput.focus();
    },
    startDelete: function(model) {
      this.model = model;
      this.$bvModal.show("simple-modal");
    },
    doDelete: function() {
      this.$ga.event("delete-model");
      this.$http.delete(utils.modelApi + "/" + this.model.id).then(() => {
        this.refreshModels();
      });
    },
    cancelDelete: function() {
      this.model = defaultModel;
    },
    showModel(model) {
      this.$router.push(`/models/${model.id}`);
    },
    editModel: function(model) {
      this.$router.push(`/models/${model.id}/edit`);
    },
    getImgUrl: function(model) {
      if (model.has_logo) {
        return `${utils.host}/models/${model.id}/download/logo`;
      }

      return require("../img/seeme-ai.svg");
    },
    getImgClass: function(model) {
      if (model.has_logo) {
        return "";
      }

      return "model-logo";
    },
    updateSearch: function(val) {
      this.search = val;
      this.filtered_models = this.filter_models();
    },
    updateShareType: function(item) {
      this.share_type = item.id;

      this.filtered_models = this.filter_models();
    }
  },
  mounted() {
    this.refreshModels();
    this.$ga.page("/");
    this.$root.$on("updated_search", val => {
      this.updateSearch(val);
    });
    this.$root.$on("updated_share_type", item => {
      this.updateShareType(item);
    });

    utils.showMenu(true, true, this);
  }
};
</script>
