<template>
  <div>
    <section class="main">
      <Header
        :entity="headerEntity"
        :actionLabel="actionLabel"
        :action="goToApp"
        :showLogo="true"
      />
      <div class="container-fluid">
        <div class="login-box">
          <div class="row">
            <div class="col-sm text-start">
              <div class="mx-auto text-left px-5 py-5 my-2">
                <p>A faster way to operate, integrate, and iterate on AI.</p>
                <p>SeeMe.ai is currently in preview.</p>
                <p></p>
                <!-- <p><a href="/todo">Learn more</a></p> -->
              </div>
            </div>
            <div class="col-sm">
              <div class="bg-light mx-5 py-5">
                <div class="login-box-body">
                  <form accept-charset="utf-8">
                    <div v-if="error" class="error">{{ error }}</div>
                    <div class="form-group">
                      <input
                        id="username-input"
                        v-model="username"
                        @keyup.enter="login"
                        v-on:keyup.stop.prevent
                        required="required"
                        class="form-control"
                      />
                      <label
                        @click="utils.focus('username-input')"
                        class="form-label"
                        >Username or email</label
                      >
                    </div>
                    <div class="form-group has-feedback">
                      <input
                        id="password-input"
                        type="password"
                        v-model="password"
                        @keyup.enter="login"
                        v-on:keyup.stop.prevent
                        required="required"
                        class="form-control mx-auto"
                      />
                      <label
                        @click="utils.focus('password-input')"
                        class=" form-label"
                        >Password</label
                      >
                    </div>
                    <div class="row">
                      <div class="col-xs-4 login-button">
                        <button
                          class="btn-primary action-btn mx-auto"
                          @click="login"
                          v-on:click.stop.prevent
                          value="Log in"
                          :disabled="btn_disabled"
                        >
                          Log in
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div class="container-fluid">
          <div class="row">
            No account yet? <a class="more-link" href="/#/register"> Sign up</a>
          </div>
          <div class="row">
            Forgot your password?
            <a class="more-link" href="/#/resetpassword">Reset password</a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import * as utils from "../utils/utils.js";
import Vue from "vue";

export default {
  name: "Login",
  data: function() {
    return {
      headerEntity: { name: "Log in" },
      actionLabel: "Go to app",
      username: "",
      password: "",
      error: "",
      force_disable_btn: false,
      utils: utils
    };
  },
  methods: {
    goToApp: function() {
      this.$router.push("/");
    },
    goToRegister: function() {
      this.$router.push("/register");
    },
    login: function() {
      this.$ga.event("login");
      this.force_disable_btn = true;
      this.error = "";
      var loginData = {
        username: this.username,
        password: this.password
      };

      this.$http
        .post(utils.loginApi, loginData)
        .then(response => {
          this.force_disable_btn = false;
          if (response.ok) {
            localStorage.setItem("apikey", response.data.apikey);
            localStorage.setItem("username", response.data.username);
            localStorage.setItem("user_id", response.data.id);

            Vue.http.headers.common[
              "Authorization"
            ] = `${response.data.username}:${response.data.apikey}`;

            this.$root.$emit("toggle_menu", true);
            this.$router.push(this.$route.query.redirect || "/");
          }
        })
        .catch(err => {
          this.error = err.statusText || err;
          this.force_disable_btn = false;
        });
    }
  },
  mounted() {
    utils.showMenu(false, false, this);
    this.$ga.page("login");
  },
  computed: {
    btn_disabled: function() {
      if (this.force_disable_btn) {
        return this.force_disable_btn;
      }

      if (this.password.length == 0) {
        return true;
      }

      if (this.username.length == 0) {
        return true;
      }

      return false;
    }
  }
};
</script>
