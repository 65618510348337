<template>
  <div id="edit-view">
    <section class="main pt-3">
      <SimpleModal
        :title="deleteModalTitle"
        :name="deleteName"
        :ok="modalOk"
        :hidden="cancelDelete"
      ></SimpleModal>
      <Header
        :entity="dataset"
        :action="updateDataset"
        :actionLabel="actionLabel"
      />
      <div class="container-fluid mt-5">
        <div class="overview pl-2 pt-1">
          <div class="my-4">
            <span class="title">Dataset info</span>
            <button
              class="second-action-button float-right action-btn"
              @click="showDataset(dataset)"
            >
              Explore
            </button>
          </div>

          <div class="row">
            <div class="col">
              <form class="mt-4">
                <div class="form-group has-feedback">
                  <input
                    id="dataset-name-input"
                    class="form-control"
                    type="text"
                    v-model="dataset.name"
                    required
                  />
                  <label
                    @click="utils.focus('dataset-name-input')"
                    class="form-label"
                    >Name</label
                  >
                </div>
                <div class="form-group has-feedback mt-5">
                  <textarea
                    id="dataset-description-input"
                    class="form-control"
                    v-model="dataset.description"
                    required
                    rows="10"
                  ></textarea>
                  <label
                    @click="utils.focus('dataset-description-input')"
                    class="form-label"
                    >Description</label
                  >
                </div>

                <div class="form-group">
                  <label
                    class="col-sm-2 font-weight-bold"
                    for="dataset-content-type"
                  >
                    Type:
                  </label>
                  <multiselect
                    i="dataset-content-type"
                    v-model="dataset.content_type"
                    :options="dataset_content_type_options"
                    :showLabels="false"
                  >
                  </multiselect>
                </div>
                <div class="form-group">
                  <custom-checkbox
                    title="Multi-label"
                    :entity="dataset"
                    property="multi_label"
                    text="Add multiple labels for every dataset item."
                    input_id="multi-label-input"
                    :disabled="true"
                  />
                </div>
                <div class="form-group has-feedback pt-3" v-if="dataset.id">
                  <input
                    class="form-control disabled-input sided-with-copy"
                    type="text"
                    v-model="dataset.id"
                    required
                    onkeypress="return false;"
                  />
                  <label class="form-label pt-1">Id</label>
                  <a
                    class="hover-larger pointer"
                    @click="copyToClipboard(dataset.id)"
                    @mouseout="resetCopyLabel()"
                    v-b-popover.hover.top="getCopyLabel()"
                    ><i class="fas fa-copy"
                  /></a>
                </div>
              </form>
            </div>
            <div class="col">
              <div
                class="
                  d-flex
                  align-items-center
                  file-wrapper
                  align-self-center
                  h-100
                "
              >
                <div
                  class="drop-zone mx-auto"
                  @click="selectImage"
                  @dragover="dragover"
                  @dragend="enddrag"
                  @dragleave="enddrag"
                  @drop="drop"
                >
                  <span v-if="!dataset.has_logo" class="drop-zone__prompt"
                    >Drop your logo here or click to upload</span
                  >
                  <img
                    class="drop-zone__thumb"
                    v-if="dataset.has_logo"
                    v-bind:src="logo_img"
                  />
                  <input
                    id="logo-input"
                    type="file"
                    name="file"
                    accept="image/jpg, image/jpeg, image/png"
                    @change="uploadLogo"
                    class="drop-zone__input"
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="my-4">
            <span class="title">Versions</span>
          </div>
          <b-table
            striped
            hover
            :items="dataset.versions"
            :fields="version_fields"
            sort-icon-left
            responsive
          >
            <template v-slot:cell(name)="row">
              <a
                class="pointer"
                @click="
                  navigateUrl([
                    {
                      name: 'datasets',
                      val: row.item.dataset_id
                    },
                    {
                      name: 'versions',
                      val: row.item.id
                    }
                  ])
                "
                >{{ row.item.name }}</a
              >
            </template>
            <template v-slot:cell(manage)="row">
              <a
                v-on:click.stop.prevent
                @click="showDatasetVersion(row.item)"
                class="btn"
              >
                <i class="far fa-edit" />
              </a>
              <a
                v-if="canDelete(row.item)"
                v-on:click.stop.prevent
                @click="startDeleteVersion(row.item)"
                class="btn"
              >
                <i class="far fa-trash-alt" />
              </a>
              <span class="btn" v-else>
                <i
                  class="far fa-trash-alt grey"
                  :title="deleteVersionTitle(row.item)"
                />
              </span>
            </template>
          </b-table>

          <hr />
          <div>
            <div class="title">Public</div>
            <custom-checkbox
              title=""
              :entity="dataset"
              property="public"
              text="Share your dataset with all SeeMe.ai users."
              input_id="public-input"
            />
          </div>
          <hr />
          <div>
            <div class="title">Shares</div>

            <form class="mt-4">
              <b-table
                striped
                hover
                :items="shares"
                :fields="share_fields"
                sort-icon-left
                responsive
                class="my-4"
              >
                <template v-slot:cell(delete)="row">
                  <a
                    v-if="canDelete(row.item)"
                    v-on:click.stop.prevent
                    @click="startDeleteShare(row.item)"
                    class="btn"
                  >
                    <i class="far fa-trash-alt" />
                  </a>
                  <div v-else>
                    <i class="far fa-trash-alt grey" />
                  </div>
                </template>
              </b-table>
              <div class="d-inline mt-4">
                <div class="form-group w-25 has-feedback">
                  <input
                    id="config-input"
                    class="form-control d-inline"
                    type="text"
                    v-model="email"
                    required
                  />
                  <label for="config-input" class="form-label d-inline"
                    >Invite via email</label
                  >
                </div>
                <div class="form-group">
                  <button
                    :disabled="inviteBtnDisabled"
                    d
                    @click="invite()"
                    class="second-action-button action-btn d-inline"
                  >
                    Invite
                  </button>
                </div>
              </div>
            </form>
          </div>
          <hr />
          <div class="date">
            Updated at: {{ utils.showDate(dataset.updated_at) }}
          </div>
          <div class="date">
            Created at: {{ utils.showDate(dataset.created_at) }}
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import * as utils from "../utils/utils.js";

let version_fields = [
  { key: "name", sortable: true },
  { key: "description" },
  { key: "manage" }
];

let share_fields = [
  { key: "email", sortable: true },
  { key: "firstname", sortable: true, label: "First name" },
  { key: "name", sortable: true },
  { key: "delete" }
];

let defaultCopyLabel = "Copy dataset id";

export default {
  data: function() {
    return {
      dataset: {
        versions: [],
        has_logo: false
      },
      user_id: "",
      versionToDelete: {},
      email: "",
      shares: [],
      checked: undefined,
      logo_img: "",
      dataset_content_type_options: utils.dataset_content_types,
      actionLabel: "Save",
      version_fields: version_fields,
      share_fields: share_fields,
      deleteModalTitle: "",
      modalOk: this.deleteVersion,
      deleteName: "",
      shareToDelete: {},
      utils: utils,
      copyLabel: defaultCopyLabel
    };
  },
  methods: {
    getDataset: function() {
      this.$http
        .get(`${utils.datasetsApi}/${this.$route.params.id}`)
        .then(response => {
          this.dataset = response.data;
          this.logo_img = this.getImgUrl(this.dataset);

          if (utils.canEdit(this.dataset)) {
            this.getShares();
            this.$ga.page("edit-dataset");
          } else {
            this.$router.push("/");
          }
        })
        .catch(err => {
          if (err.status == this.utils.http_status_not_found) {
            this.$router.push({
              name: "NotFound"
            });
          } else {
            this.$router.push({
              name: "Login",
              query: { redirect: this.$route.fullPath }
            });
          }
        });
    },
    getShares: function() {
      this.$http
        .get(`${utils.datasetsApi}/${this.$route.params.id}/share`)
        .then(response => {
          this.shares = response.data;
        })
        .catch(() => {
          utils.redictLogin(this);
        });
    },
    updateDataset: function() {
      this.$http
        .put(`${utils.datasetsApi}/${this.dataset.id}`, this.dataset)
        .then(response => {
          this.checked = response.data.public ? "on" : "off";
        });
    },
    selectImage: function() {
      utils.el("logo-input").click();
    },
    getImgUrl: function(dataset) {
      if (dataset.has_logo) {
        return `${utils.host}/datasets/${dataset.id}/logo`;
      }

      return require("../img/seeme_placeholder.png");
    },
    dragover: function(e) {
      e.preventDefault();

      let dropzoneEl = document.querySelector(".drop-zone");
      dropzoneEl.classList.add("drop-zone--over");
    },
    enddrag: function() {
      let dropzoneEl = document.querySelector(".drop-zone");
      dropzoneEl.classList.remove("drop-zone--over");
    },
    drop: function(e) {
      e.preventDefault();

      if (e.dataTransfer.files.length) {
        let inputEl = utils.el("logo-input");
        inputEl.files = e.dataTransfer.files;
        this.uploadLogo();
      }

      let dropzoneEl = document.querySelector(".drop-zone");
      dropzoneEl.classList.remove("drop-zone--over");
    },
    uploadLogo: function() {
      var uploadFiles = utils.el("logo-input").files;
      var formData = new FormData();

      formData.append("file", uploadFiles[0]);
      this.$http
        .post(`${utils.datasetsApi}/${this.dataset.id}/upload`, formData)
        .then(res => {
          this.dataset.has_logo = res.body.has_logo;
          // Added `Date.now()` to trigger a refresh of the logo, because the url for the logo is always the same atm.
          this.logo_img = this.getImgUrl(res.body) + `?${Date.now()}`;

          this.dataset.logo = res.body.logo;
        });
    },
    canInvite: function() {
      return utils.isValidEmail(this.email);
    },
    invite: function() {
      var share = {
        email: this.email,
        entity_id: this.dataset.id,
        entity_type: utils.dataset_share_type
      };

      this.$http
        .post(`${utils.datasetsApi}/${this.dataset.id}/share`, share)
        .then(() => {
          this.getShares();
          this.email = "";
        });
    },
    canDelete: function(item) {
      // Owner can delete
      if (this.dataset.user_id == utils.user_id()) {
        return true;
      }

      // You can delete your own shares
      if (item.request_user_id == utils.user_id()) {
        return true;
      }

      if (item.user_id == utils.user_id()) {
        return true;
      }

      return false;
    },
    deleteVersionTitle: function(version) {
      if (!this.canDelete(version)) {
        return "You do not have permission to delete this version.";
      }
    },
    deleteShare: function() {
      this.$http
        .delete(
          `${utils.datasetsApi}/${this.dataset.id}/share/${this.shareToDelete.share_id}`
        )
        .then(() => {
          this.getShares();
        });
    },
    deleteVersion: function() {
      this.$http
        .delete(
          `${utils.datasetsApi}/${this.dataset.id}/versions/${this.versionToDelete.id}`
        )
        .then(() => {
          this.dataset.versions = this.dataset.versions.filter(v => {
            return v.id != this.versionToDelete.id;
          });
        });
    },
    showDataset(dataset) {
      this.$router.push(`/datasets/${dataset.id}`);
    },
    showDatasetVersion(version) {
      this.$router.push(`/datasets/${this.dataset.id}/versions/${version.id}`);
    },
    startDeleteVersion: function(version) {
      this.deleteModalTitle = "Delete dataset version";
      this.versionToDelete = version;
      this.modalOk = this.deleteVersion;
      this.deleteName = version.name;
      this.$bvModal.show("simple-modal");
    },
    startDeleteShare: function(share) {
      this.deleteModalTitle = "Delete dataset share";
      this.shareToDelete = share;
      this.modalOk = this.deleteShare;
      this.deleteName = share.email;
      this.$bvModal.show("simple-modal");
    },
    cancelDelete: function() {
      this.versionToDelete = {};
      this.shareToDelete = {};
    },
    navigateUrl: function(params) {
      let url = utils.generateUrl(params);

      this.$router.push(url);
    },
    copyToClipboard: function(text) {
      this.utils.copyToClipboard(text);
      this.copyLabel = "Dataset id copied to clipboard!";
    },
    resetCopyLabel: function() {
      setTimeout(() => {
        this.copyLabel = defaultCopyLabel;
      }, 500);
    },
    getCopyLabel: function() {
      return this.copyLabel;
    }
  },
  mounted() {
    this.getDataset();
    this.$ga.page("edit-dataset");
    utils.showMenu(true, true, this);
  },

  computed: {
    inviteBtnDisabled: function() {
      if (!this.canInvite()) {
        return true;
      }

      if (!utils.isValidEmail(this.email)) {
        return true;
      }

      return false;
    }
  }
};
</script>
